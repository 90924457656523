import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080 1008">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd"  transform="">


<path fill="#FEFFFF" opacity="1.000000" stroke="none" 
	d="
M700.000000,1009.000000 
	C466.694489,1009.000000 233.888977,1009.000000 1.041738,1009.000000 
	C1.041738,673.067993 1.041738,337.135956 1.041738,1.101970 
	C360.888977,1.101970 720.777954,1.101970 1080.833496,1.101970 
	C1080.833496,336.999939 1080.833496,672.999939 1080.833496,1009.000000 
	C954.135681,1009.000000 827.317810,1009.000000 700.000000,1009.000000 
M803.847534,603.106934 
	C803.847534,603.106934 803.783203,603.168518 803.908875,602.538757 
	C803.553162,602.079895 803.197449,601.621033 802.637817,600.475525 
	C796.543274,589.818115 790.441101,579.164978 784.355835,568.502258 
	C771.602661,546.155457 758.961426,523.743958 746.082642,501.469788 
	C731.955078,477.035736 717.567322,452.752258 703.376770,428.354431 
	C692.438232,409.547852 681.621643,390.670319 670.765747,371.815796 
	C663.231995,358.731293 655.786499,345.595398 648.163330,332.563354 
	C646.024597,328.907166 643.396667,325.537140 640.924500,321.503296 
	C640.828430,321.352264 640.732361,321.201202 640.725159,320.336853 
	C640.536743,319.708405 640.462585,319.014069 640.143616,318.460907 
	C624.267334,290.926971 608.406799,263.383667 592.431946,235.906921 
	C590.240662,232.137909 587.469116,228.706253 584.759521,224.544403 
	C584.640198,224.379715 584.520935,224.215012 584.604370,223.377960 
	C579.609558,214.473221 574.727478,205.502625 569.577087,196.688782 
	C566.542664,191.496002 563.082642,186.551895 559.786316,181.250092 
	C559.786316,181.250092 559.574890,181.121521 559.687439,180.428711 
	C558.604614,178.403091 557.521729,176.377472 555.835144,173.222351 
	C551.945801,180.709579 548.737671,186.885468 545.164673,193.531754 
	C545.122437,193.725662 545.080200,193.919571 544.398682,194.256927 
	C534.103882,211.861710 523.795593,229.458649 513.559326,247.097397 
	C513.104675,247.880768 513.306580,249.045166 513.078430,250.021271 
	C513.078430,250.021271 513.069031,250.145630 512.408142,250.224060 
	C512.139038,250.413269 511.766937,250.542114 511.615631,250.800339 
	C504.398529,263.115326 497.094543,275.382477 490.148163,287.848785 
	C489.369293,289.246521 490.154663,292.024139 491.037872,293.737396 
	C493.083099,297.704651 495.724731,301.358795 497.961670,305.233917 
	C506.259857,319.608978 514.388062,334.083008 522.770325,348.408478 
	C541.224060,379.946320 559.800049,411.412659 578.294617,442.926636 
	C579.094971,444.290405 579.582336,445.837830 580.549133,448.069885 
	C578.047119,448.213593 576.310852,448.397888 574.574280,448.400543 
	C543.278198,448.448303 511.982086,448.513062 480.686157,448.453979 
	C476.905121,448.446838 473.763245,449.135559 471.758209,452.611542 
	C465.288300,463.827728 458.772583,475.018005 452.393982,486.285889 
	C451.206757,488.383118 450.573273,490.793854 449.271149,493.597321 
	C449.204681,493.813263 449.138214,494.029205 448.426300,494.334808 
	C446.425110,497.895966 444.423920,501.457153 442.010162,505.622192 
	C435.341125,517.067932 428.569763,528.455688 422.037781,539.979126 
	C417.388092,548.181946 413.060638,556.567322 408.023499,565.189270 
	C406.242218,567.967224 403.906281,570.538635 402.780548,573.560852 
	C398.953766,583.834351 391.538788,586.911499 381.368530,585.121155 
	C379.522003,584.796082 377.701569,584.322510 375.869141,583.917542 
	C376.465149,582.107178 376.851746,580.192078 377.695038,578.505371 
	C380.634308,572.626343 383.731689,566.826233 387.086365,560.704468 
	C387.086365,560.704468 387.107788,560.275696 387.727234,560.027161 
	C388.354492,558.685364 388.981750,557.343567 389.949615,555.640137 
	C389.949615,555.640137 390.059479,555.155701 390.704712,554.948853 
	C392.342133,551.966431 393.979553,548.984070 395.934998,545.609924 
	C395.972198,545.445923 396.009369,545.281860 396.699402,544.925293 
	C405.820404,529.325073 415.006409,513.762329 424.027557,498.104584 
	C427.239197,492.530243 430.050720,486.725403 432.997955,481.002502 
	C432.997955,481.002502 433.026733,481.046448 433.657776,480.882019 
	C434.278564,479.592773 434.899384,478.303528 436.033905,476.569183 
	C436.372101,475.758911 436.710297,474.948639 437.613220,473.951355 
	C437.894928,473.305084 438.176605,472.658813 438.984283,471.515625 
	C448.219269,455.783264 457.474365,440.062561 466.654510,424.298218 
	C467.465942,422.904785 467.788055,421.226379 468.956818,419.349060 
	C470.931854,416.302032 472.851288,413.216736 474.909912,410.227295 
	C475.711426,409.063293 476.826508,408.115265 477.798798,407.068878 
	C478.939117,408.136169 480.381989,409.018707 481.166504,410.303314 
	C484.284302,415.408630 486.896912,420.851013 490.333099,425.719269 
	C491.700195,427.656189 494.697998,429.501038 496.993896,429.551605 
	C511.971802,429.881622 526.960876,429.726288 541.946045,429.668762 
	C543.437988,429.663055 544.928284,429.248779 547.040283,428.930603 
	C523.905090,389.107605 501.247681,350.106934 478.155426,310.357819 
	C476.758392,312.215454 475.779175,313.276733 475.070709,314.495453 
	C463.126770,335.041565 451.160980,355.575409 439.342926,376.193939 
	C436.801910,380.627228 434.821686,385.381958 432.108337,390.363617 
	C431.267975,391.356354 430.258698,392.250702 429.613892,393.357361 
	C424.375031,402.348175 419.121307,411.332214 414.037872,420.411133 
	C409.542328,428.440063 405.259583,436.588165 400.249969,444.949463 
	C398.985779,447.294922 397.721588,449.640381 395.939117,452.450348 
	C395.559082,453.275543 395.179047,454.100769 394.183746,455.148376 
	C393.443451,456.761261 392.703156,458.374146 391.392670,460.333923 
	C389.514496,463.861328 387.636353,467.388733 385.173706,471.235809 
	C384.041016,472.850952 382.783905,474.393738 381.795380,476.092743 
	C374.230499,489.095062 366.652771,502.090759 359.216675,515.166748 
	C355.485107,521.728516 352.040955,528.453735 347.847137,535.360291 
	C347.513336,536.406250 347.179565,537.452271 346.213745,538.841919 
	C345.777832,539.886597 345.341919,540.931274 344.271240,542.177429 
	C343.054382,543.923157 341.703857,545.591492 340.641144,547.426453 
	C328.154633,568.986755 315.709137,590.570862 303.257751,612.151489 
	C299.575836,618.533020 295.909424,624.923462 291.937744,631.828186 
	C293.841370,632.230042 294.914825,632.653992 295.988647,632.654846 
	C337.079712,632.689209 378.170837,632.706177 419.261688,632.606445 
	C420.889038,632.602539 423.328094,631.912292 424.010010,630.739746 
	C429.244385,621.740295 434.137024,612.542114 439.765106,603.100769 
	C440.871124,601.112122 441.977142,599.123474 443.691559,596.887329 
	C444.059479,595.922913 444.427429,594.958557 445.086212,593.570557 
	C445.116150,593.401917 445.146057,593.233215 445.688751,592.779541 
	C445.891693,592.246216 446.094604,591.712830 446.896759,590.933411 
	C449.933228,585.994751 453.124664,581.143616 455.972961,576.098694 
	C461.421387,566.448486 466.674561,556.688110 472.611389,546.852173 
	C473.081512,545.919556 473.551605,544.986877 474.581238,543.383789 
	C474.699707,542.821838 474.818207,542.259949 475.553162,541.565247 
	C476.979340,539.036316 478.405518,536.507446 480.441559,533.448914 
	C480.628784,532.953003 480.816040,532.457031 481.607666,531.765869 
	C481.975342,530.847473 482.343018,529.929138 483.024231,528.651611 
	C483.024231,528.651611 483.078796,528.178040 483.696594,527.914246 
	C488.154877,519.950989 492.613129,511.987701 496.999603,504.030914 
	C496.999603,504.030914 497.063354,504.064575 497.674103,503.805084 
	C500.724762,497.235443 506.110382,496.502563 512.767822,496.537750 
	C561.388428,496.794739 610.010925,496.675232 658.632874,496.642120 
	C660.512756,496.640839 662.392456,496.309692 664.429321,496.117401 
	C664.174500,494.991699 664.177490,494.454895 663.944824,494.055267 
	C643.958130,459.727722 623.990295,425.389099 603.913757,391.114136 
	C600.510681,385.304535 596.634705,379.772003 592.746948,373.448090 
	C592.571106,373.293549 592.395325,373.139008 592.379700,372.343842 
	C591.226135,370.632477 590.072632,368.921082 588.932922,367.061371 
	C588.932922,367.061371 588.784485,367.074432 588.696594,366.341492 
	C587.074097,363.133179 585.659607,359.797241 583.785156,356.743561 
	C580.033813,350.632080 576.030701,344.675079 572.091064,338.009613 
	C571.848267,337.663910 571.605530,337.318207 571.543152,336.300323 
	C567.232910,328.724182 562.980896,321.114014 558.583069,313.589020 
	C556.877747,310.671051 554.842834,307.945770 552.976379,305.036987 
	C552.976379,305.036987 552.879089,305.031647 552.849182,304.308655 
	C550.921143,300.652161 549.238342,296.835449 546.987915,293.389496 
	C545.165344,290.598694 545.305237,288.460754 546.991699,285.668884 
	C549.432556,281.628174 551.324646,277.255981 554.088379,272.415863 
	C554.423706,271.933655 554.759094,271.451477 555.737671,271.435822 
	C556.742676,272.471497 557.987122,273.363281 558.710815,274.567932 
	C561.687195,279.522064 564.416199,284.625153 567.406006,289.570831 
	C570.520203,294.722260 573.829834,299.755493 577.023987,304.963135 
	C577.023987,304.963135 577.149414,304.978302 577.138672,305.635468 
	C577.755493,306.419281 578.372314,307.203125 579.114929,308.565826 
	C579.239685,308.718842 579.364502,308.871857 579.394653,309.684143 
	C580.588013,311.467010 581.781433,313.249878 583.104004,315.587830 
	C583.228149,315.731659 583.352295,315.875519 583.371704,316.591309 
	C583.891541,317.110931 584.411377,317.630585 584.998718,318.634033 
	C584.998718,318.634033 585.303772,319.015564 585.261047,319.717468 
	C588.507202,325.126740 591.753357,330.535980 595.189941,336.568085 
	C595.342712,336.722290 595.495544,336.876526 595.492126,337.642303 
	C596.327942,338.746490 597.163757,339.850647 598.131653,341.549774 
	C598.261597,341.705872 598.391602,341.862000 598.434692,342.692657 
	C604.424744,353.244019 610.381714,363.814514 616.437073,374.328247 
	C617.461487,376.106903 618.909668,377.641479 620.258728,379.680359 
	C620.258728,379.680359 620.598694,379.897278 620.438904,380.535919 
	C621.282898,381.708344 622.126892,382.880798 623.078430,384.609009 
	C623.207031,384.747070 623.335693,384.885132 623.372803,385.605164 
	C623.879272,386.154572 624.385742,386.703979 624.933777,387.906555 
	C625.156555,388.274994 625.379395,388.643433 625.439697,389.617157 
	C626.349731,390.801697 627.259766,391.986206 628.291077,393.611572 
	C628.291077,393.611572 628.646851,393.898743 628.445312,394.571503 
	C632.697815,401.987854 636.950317,409.404236 641.099487,417.544464 
	C641.742859,418.353729 642.386292,419.162964 643.196411,420.340027 
	C643.196411,420.340027 643.349121,420.713837 643.188171,421.460541 
	C646.111450,425.983521 649.034790,430.506500 652.396240,435.459320 
	C652.703979,436.217804 653.011719,436.976318 653.158752,438.396301 
	C653.729736,438.960815 654.300720,439.525299 655.011841,440.487335 
	C655.011841,440.487335 655.149841,440.885681 655.038757,441.605408 
	C655.718872,442.380615 656.398987,443.155792 657.254944,444.333313 
	C657.254944,444.333313 657.393677,444.749878 657.309753,445.280304 
	C657.579712,445.482117 657.849670,445.683960 658.318848,446.267609 
	C658.318848,446.267609 658.495056,446.660583 658.267944,447.395447 
	C660.527954,450.915161 662.787964,454.434875 665.196960,458.342529 
	C665.196960,458.342529 665.352417,458.727844 665.204956,459.459595 
	C666.483643,461.294342 667.762390,463.129089 669.233582,465.325836 
	C669.233582,465.325836 669.399048,465.700928 669.198425,466.416351 
	C670.150635,467.592529 671.102783,468.768738 672.487610,470.054901 
	C672.487610,470.054901 672.931763,470.098938 672.929382,470.008698 
	C672.929382,470.008698 672.873901,470.079895 672.891052,470.749847 
	C673.077759,471.393188 673.264465,472.036499 673.269165,473.343994 
	C673.843567,473.896484 674.417969,474.448944 675.253723,475.493073 
	C675.520691,475.981201 675.787598,476.469330 675.975952,477.713593 
	C677.689880,480.436249 679.403809,483.158936 681.527344,486.341858 
	C682.019226,487.228455 682.511047,488.115051 682.969116,489.753265 
	C684.790222,493.367798 686.586914,496.995300 688.485107,500.568878 
	C688.616638,500.816467 689.502747,500.663208 690.354492,500.527740 
	C690.354492,500.527740 690.416077,500.174438 690.537292,500.900116 
	C690.835266,501.832031 691.133240,502.763947 691.216003,504.404938 
	C692.151855,505.596802 693.087769,506.788666 694.315552,508.517487 
	C694.676331,509.247711 695.037048,509.977905 695.190735,511.382812 
	C695.872864,512.182068 696.554993,512.981262 697.393494,514.188110 
	C697.393494,514.188110 697.526611,514.603882 697.409485,515.332825 
	C699.280945,518.547241 701.152466,521.761719 703.098328,525.665344 
	C704.075317,526.760559 705.052307,527.855774 706.013672,528.985535 
	C706.013672,528.985535 706.050903,528.978943 705.992676,529.709045 
	C706.994751,531.140259 707.996826,532.571411 709.163818,534.365234 
	C709.163818,534.365234 709.316956,534.733032 709.173157,535.470886 
	C710.471313,537.291931 711.769409,539.113037 713.227417,541.332214 
	C713.227417,541.332214 713.425415,541.712891 713.251953,542.434998 
	C715.859863,546.601318 718.467834,550.767639 721.234375,555.320740 
	C721.234375,555.320740 721.383606,555.711243 721.219482,556.374390 
	C721.800293,556.924316 722.381104,557.474243 723.259705,558.555725 
	C723.642639,559.270874 724.025574,559.986023 724.190857,561.458069 
	C725.845093,563.920227 727.499329,566.382385 729.369812,569.240723 
	C729.369812,569.240723 729.553040,569.653137 729.287048,570.305542 
	C729.863159,570.866150 730.439331,571.426758 731.301819,572.530823 
	C731.685120,573.254944 732.068420,573.979126 732.270081,575.437927 
	C733.116760,577.705505 733.963501,579.973083 735.125305,583.084534 
	C730.823975,583.084534 727.070007,582.847534 723.358826,583.144531 
	C718.720581,583.515625 715.716553,582.214355 713.475342,577.687012 
	C711.369507,573.432983 707.894470,569.856750 704.748901,565.441895 
	C704.360413,564.734558 703.971924,564.027222 703.782593,562.582947 
	C702.508301,560.733948 701.233948,558.884888 699.791687,556.661804 
	C699.791687,556.661804 699.640991,556.280396 699.814392,555.564392 
	C698.863403,554.388062 697.912476,553.211670 697.000000,552.004883 
	C697.000000,552.004883 696.961548,552.028564 697.037964,551.280334 
	C695.687805,549.190857 694.337646,547.101318 693.000000,545.000000 
	C693.000000,545.000000 692.987244,545.010315 693.025574,544.276062 
	C691.999695,542.866638 690.973755,541.457214 689.510925,539.607910 
	C689.209290,538.837524 688.907654,538.067139 688.759583,536.630066 
	C688.179016,536.082336 687.598450,535.534668 686.723816,534.427734 
	C686.075806,533.342346 685.427795,532.256958 684.785706,530.645691 
	C684.530457,530.426636 684.275208,530.207642 683.874695,529.595581 
	C683.874695,529.595581 683.728088,529.203125 683.732178,528.675110 
	C683.466125,528.468628 683.200012,528.262146 682.800171,527.655701 
	C682.800171,527.655701 682.682678,527.250549 682.838867,526.581055 
	C682.240845,526.041443 681.642761,525.501892 680.621643,524.466125 
	C679.933472,523.080688 679.245361,521.695312 678.782776,519.654175 
	C678.220337,519.080017 677.657898,518.505920 676.802124,517.542542 
	C676.583252,517.298645 676.334167,517.091125 675.846802,516.143677 
	C673.980408,515.789612 672.114258,515.128357 670.247498,515.126404 
	C620.347900,515.074829 570.448242,515.094971 520.548584,515.092407 
	C511.456207,515.091980 506.366638,519.786499 505.131195,529.607666 
	C505.131195,529.607666 504.974518,529.983826 504.298676,530.092224 
	C501.969147,534.480957 499.639648,538.869751 497.193420,543.663818 
	C497.193420,543.663818 497.073517,544.068115 496.368927,544.122742 
	C494.001892,548.487305 491.634857,552.851929 489.132751,557.607849 
	C489.132751,557.607849 488.985260,557.994690 488.302948,558.074280 
	C485.954285,562.452393 483.605591,566.830444 481.121643,571.601685 
	C481.121643,571.601685 480.982788,571.993591 480.290619,572.082825 
	C477.933746,576.448181 475.576904,580.813538 473.069733,585.565369 
	C473.069733,585.565369 472.931885,585.956482 472.264984,586.084167 
	C471.795959,586.530151 471.187775,586.896057 470.878204,587.433777 
	C462.944824,601.215820 454.967529,614.973694 447.187134,628.841919 
	C445.211212,632.363953 446.572235,633.864624 451.386200,633.865479 
	C519.293030,633.877136 587.199951,633.893799 655.106506,633.756409 
	C657.294006,633.751953 659.478210,632.121155 661.663940,631.248474 
	C660.154968,629.133057 658.645935,627.017700 656.936523,624.374817 
	C656.814758,624.018982 656.692993,623.663147 656.767639,622.571899 
	C656.056824,620.906921 655.504517,619.150818 654.608337,617.592407 
	C650.242859,610.001221 645.870300,602.411255 641.317932,594.931885 
	C639.553223,592.032593 637.308228,589.425659 634.997681,585.972473 
	C632.914551,585.347900 630.833374,584.187073 628.747925,584.179260 
	C598.029358,584.063416 567.310303,584.104736 536.591309,584.091919 
	C534.862671,584.091187 533.134033,583.943970 531.046326,583.848511 
	C531.908997,581.193665 532.560608,579.188293 533.839233,576.931152 
	C535.153442,574.846375 536.811829,572.899170 537.711487,570.648682 
	C539.702820,565.667603 543.254944,564.654419 548.283142,564.760620 
	C563.050415,565.072510 577.828430,564.844666 592.602173,564.884888 
	C611.688293,564.936829 630.774231,565.043640 649.939148,565.529907 
	C649.939148,565.529907 650.087952,565.912781 650.102844,566.643738 
	C652.403931,570.094177 654.704956,573.544678 657.165771,577.374329 
	C657.165771,577.374329 657.309753,577.759644 657.158936,578.500000 
	C667.241516,596.190002 677.224548,613.938782 687.580261,631.467346 
	C688.488892,633.005310 691.839355,633.758911 694.059326,633.766235 
	C734.132385,633.899109 774.206055,633.865051 814.279541,633.843323 
	C815.759338,633.842529 817.449585,633.999512 818.653503,633.356201 
	C819.655518,632.820740 820.949280,630.684448 820.632263,630.169678 
	C818.273010,626.338928 815.544189,622.735718 812.488281,618.567749 
	C811.800720,617.178284 811.113098,615.788879 810.695618,613.755005 
	C810.174805,613.129883 809.653992,612.504700 809.042725,611.174805 
	C808.047180,609.764587 807.051636,608.354370 805.692505,606.832825 
	C805.692505,606.832825 805.312561,606.848999 805.177795,606.186890 
	C804.968506,605.544861 804.759155,604.902771 804.657898,603.742859 
	C804.387207,603.560547 804.116577,603.378235 803.847534,603.106934 
M583.134155,817.730896 
	C583.099915,818.495544 583.065613,819.260193 582.469971,820.510071 
	C582.038391,824.682983 581.606812,828.855835 581.077026,833.687439 
	C581.059509,834.131042 581.041992,834.574707 580.611877,835.321045 
	C580.694275,835.908020 580.776672,836.494995 580.801758,837.645142 
	C580.752502,838.019287 580.703186,838.393433 580.041931,839.130798 
	C579.725403,842.418823 579.408875,845.706909 579.013733,849.648682 
	C578.995239,850.087341 578.976685,850.525940 578.607849,851.267029 
	C578.378479,851.560730 578.393005,851.841797 578.462524,852.899475 
	C578.343933,853.978210 578.225281,855.057007 577.564819,856.571533 
	C576.341736,857.425842 575.118591,858.280090 573.479065,858.984802 
	C573.479065,858.984802 573.040466,858.925903 572.398926,858.399475 
	C565.924255,858.586670 559.449524,858.773926 552.148315,858.971436 
	C550.770691,858.976318 549.393066,858.981201 547.904724,858.056091 
	C547.562134,835.456116 547.236755,812.855774 546.843201,790.256714 
	C546.812561,788.497375 546.304565,786.746399 546.018921,784.055298 
	C545.936157,771.030945 545.853394,758.006653 546.372253,744.449524 
	C546.372253,740.461182 546.372253,736.472839 546.372253,732.175415 
	C539.711182,732.175415 534.420837,732.164246 529.130554,732.178406 
	C523.185852,732.194336 522.127930,733.113220 522.211304,739.087219 
	C522.449280,756.142090 522.804993,773.195435 523.169922,790.248230 
	C523.189575,791.168335 523.717346,792.077576 524.020386,793.930115 
	C524.080444,803.624695 524.140503,813.319275 523.597107,823.589172 
	C523.469299,837.684937 523.316650,851.780701 523.282288,865.876709 
	C523.280457,866.633667 524.273193,867.392944 524.821289,868.944519 
	C524.829041,870.267517 524.836731,871.590454 524.412781,873.413025 
	C524.586121,874.622192 524.759460,875.831299 525.046631,877.442383 
	C525.046631,877.442383 525.254517,877.804810 525.278381,878.502686 
	C529.833496,878.311096 534.388672,878.119507 539.721802,877.937012 
	C540.499817,877.933350 541.277771,877.929688 542.651184,878.490234 
	C558.514832,878.598022 574.378418,878.714844 590.242249,878.776917 
	C590.815918,878.779175 591.391724,878.241760 592.782959,877.939941 
	C595.342590,878.107788 596.749207,877.242737 596.982788,874.360596 
	C597.478821,868.240479 598.453613,862.158203 598.911743,856.036438 
	C599.132324,853.089294 600.162354,851.907837 603.165100,851.995544 
	C608.321106,852.146362 613.492065,852.162354 618.643982,851.938904 
	C621.939209,851.795959 622.834656,853.167847 623.135986,856.270020 
	C623.728760,862.371216 624.652588,868.460938 625.889893,874.462524 
	C626.158081,875.763550 628.248535,877.481262 629.632080,877.612732 
	C633.921387,878.020264 638.276978,877.664429 642.602051,877.782410 
	C645.874573,877.871704 646.998291,876.787659 646.430542,873.319702 
	C640.183167,835.156006 634.068970,796.970459 627.899231,758.794067 
	C626.531799,750.333191 625.088440,741.884644 623.646057,733.226074 
	C614.602112,733.226074 606.128235,733.078735 597.670959,733.387390 
	C596.423828,733.432922 594.492065,735.474731 594.190369,736.875793 
	C593.180176,741.566467 592.765442,746.385376 591.385620,751.300110 
	C591.292480,753.864563 591.199280,756.429077 591.048462,759.650146 
	C591.016357,760.088379 590.984314,760.526672 590.386536,761.336792 
	C590.333435,762.884277 590.280334,764.431763 590.139099,766.716064 
	C590.067444,767.454590 589.995789,768.193054 589.290039,769.125671 
	C589.253662,770.413513 589.217285,771.701294 589.101135,773.659058 
	C589.070068,774.107788 589.039062,774.556458 588.467773,775.518555 
	C588.042053,779.673767 587.616272,783.828979 587.115540,788.659058 
	C587.083557,789.110596 587.051575,789.562134 586.498901,790.456787 
	C586.388367,792.300293 586.277832,794.143860 586.096741,796.658630 
	C586.067200,797.107666 586.037598,797.556702 585.526611,798.436768 
	C585.404785,799.953735 585.282959,801.470642 585.086243,803.657043 
	C585.056763,804.105164 585.027283,804.553223 584.479797,805.512390 
	C584.075623,809.333984 583.671509,813.155640 583.134155,817.730896 
M336.212433,865.399536 
	C336.513794,870.008667 336.815155,874.617798 337.122467,879.318359 
	C344.596710,879.318359 351.621155,879.318359 357.539307,879.318359 
	C357.539307,861.226746 357.974823,843.592224 357.354492,825.995056 
	C357.046906,817.270447 355.445587,808.324097 346.267303,802.721130 
	C352.842712,799.259033 355.414734,793.866150 356.223877,787.493958 
	C357.806396,775.031494 357.822571,762.564392 354.685608,750.350464 
	C352.606171,742.254211 347.251587,736.575867 338.649780,736.085083 
	C325.548828,735.337646 312.391693,735.629395 299.266937,735.211487 
	C295.169250,735.080933 294.203308,736.633789 294.267731,740.458069 
	C294.643402,762.764404 294.793243,785.074463 295.044250,807.382996 
	C295.299042,830.025452 295.538483,852.668396 295.950012,875.308105 
	C295.978302,876.865234 297.311066,879.685486 298.165527,879.735718 
	C304.576874,880.112671 311.020508,879.940918 317.893280,879.940918 
	C317.893280,874.692505 317.945465,870.228760 317.883911,865.766541 
	C317.658875,849.454285 317.347931,833.142822 317.223419,816.829895 
	C317.211273,815.242310 317.815521,812.730835 318.893036,812.205383 
	C325.233398,809.113464 334.754639,814.073669 335.100098,821.017090 
	C335.819092,835.468384 335.846191,849.954102 336.212433,865.399536 
M656.024292,795.500000 
	C656.267700,822.542969 656.511108,849.585938 656.756470,876.844788 
	C667.377014,876.844788 677.010925,876.851501 686.644775,876.838318 
	C688.308228,876.836060 689.988159,876.883972 691.632324,876.680603 
	C707.563354,874.709778 715.028687,867.777588 717.792480,851.972351 
	C718.617126,847.255981 718.984680,842.403015 718.997803,837.611084 
	C719.053528,817.298828 719.053284,796.983643 718.736206,776.674744 
	C718.619873,769.230164 718.020264,761.727966 716.842957,754.379944 
	C714.914368,742.343140 706.539734,734.037048 694.516479,733.042297 
	C683.275146,732.112366 671.919434,732.644592 660.623962,732.248779 
	C656.510864,732.104614 655.126465,733.367554 655.222046,737.591064 
	C655.651611,756.557007 655.783813,775.529724 656.024292,795.500000 
M508.093506,803.488953 
	C507.717163,789.024658 507.957275,774.509583 506.772430,760.111816 
	C505.796936,748.257568 500.822479,737.692871 488.137695,734.406067 
	C475.356140,731.094238 462.509399,731.047852 452.553650,742.027771 
	C446.424866,748.787048 444.404053,757.360962 444.300751,766.074768 
	C444.016571,790.049194 444.163788,814.031311 444.410950,838.007568 
	C444.470551,843.789368 445.131989,849.651001 446.282379,855.320496 
	C450.919250,878.172852 476.615692,888.434570 495.671417,875.094604 
	C504.701477,868.773254 507.339722,858.913391 507.827484,848.850769 
	C508.542908,834.092102 508.064392,819.275513 508.093506,803.488953 
M283.033264,863.317993 
	C281.440887,853.149841 279.866333,842.978821 278.252197,832.814087 
	C275.465973,815.268738 272.653564,797.727600 269.855133,780.184204 
	C267.763153,767.069763 265.771881,753.937683 263.468689,740.860535 
	C263.170410,739.166870 261.327789,736.471924 260.105011,736.418274 
	C251.337402,736.033691 242.545074,736.212646 233.547867,736.212646 
	C227.067719,784.650330 220.671509,832.460571 214.185806,880.939697 
	C220.937607,880.939697 227.213058,881.076172 233.468399,880.782959 
	C234.340912,880.742065 235.661926,878.665466 235.841003,877.399048 
	C236.723328,871.159424 237.411133,864.885010 237.915161,858.602600 
	C238.144272,855.746826 239.498886,854.942322 242.097244,854.985840 
	C247.257355,855.072327 252.425232,855.132874 257.580017,854.940369 
	C260.772675,854.821106 261.865356,856.034180 262.197968,859.203491 
	C262.939667,866.270447 264.153412,873.287781 265.176697,880.338257 
	C272.124969,880.338257 278.651978,880.338257 285.769409,880.338257 
	C284.834778,874.593018 283.987946,869.387512 283.033264,863.317993 
M368.037476,858.513428 
	C367.153229,865.412903 366.268982,872.312378 365.293945,879.920349 
	C371.886383,879.920349 378.159637,880.058960 384.412537,879.765015 
	C385.309784,879.722839 386.676147,877.674377 386.857117,876.415161 
	C387.730804,870.335938 388.401428,864.218994 388.876556,858.094666 
	C389.098572,855.232849 390.020905,853.900208 393.096771,853.995239 
	C398.422150,854.159851 403.759827,854.102844 409.087189,853.953613 
	C411.855194,853.876099 412.804565,855.019348 413.117035,857.704712 
	C413.846008,863.970154 414.831848,870.210999 415.928894,876.423706 
	C416.140320,877.620911 417.293518,879.567261 418.081177,879.601929 
	C424.139740,879.868469 430.215271,879.748474 436.507721,879.748474 
	C436.428864,878.105835 436.458252,877.461182 436.359253,876.836853 
	C431.493500,846.159485 426.617004,815.483704 421.741028,784.807922 
	C419.420197,770.207031 416.960815,755.626099 414.859924,740.993652 
	C414.227692,736.590149 412.763519,734.682617 407.963470,735.048828 
	C401.501617,735.541870 394.976929,735.298706 388.481079,735.218567 
	C385.676880,735.184021 384.335388,736.039185 383.946899,739.090027 
	C381.428131,758.870850 378.725159,778.628296 376.067596,798.391357 
	C373.412415,818.136536 370.739777,837.879272 368.037476,858.513428 
M868.817566,864.259705 
	C869.222168,860.126282 869.501404,855.973816 870.120911,851.872925 
	C870.273254,850.864685 871.512878,849.249878 872.290771,849.227234 
	C879.375671,849.021423 886.468994,849.106445 893.439209,849.106445 
	C894.770203,857.945435 896.000488,866.115601 897.294922,874.711792 
	C903.376526,874.711792 909.176880,874.866333 914.950439,874.543152 
	C915.805237,874.495239 917.350037,872.076660 917.192871,870.947327 
	C915.796936,860.919983 914.067871,850.939453 912.478149,840.938538 
	C909.246277,820.606628 906.064514,800.266724 902.814880,779.937683 
	C900.377197,764.687927 897.872437,749.448792 895.378906,734.207947 
	C895.027466,732.059387 894.815918,729.999512 891.618103,730.089661 
	C883.963867,730.305542 876.295776,730.083923 868.647400,730.391296 
	C867.412659,730.440979 865.364563,732.171143 865.191162,733.345825 
	C863.300903,746.146912 861.746643,758.997192 860.027832,771.824219 
	C857.248901,792.563049 854.389221,813.291077 851.614014,834.030334 
	C849.815613,847.469849 848.106934,860.921326 846.278503,874.979431 
	C850.172607,874.979431 853.462463,874.981018 856.752319,874.979065 
	C866.902710,874.973022 866.902710,874.972534 868.817566,864.259705 
M756.564575,731.187134 
	C754.233521,731.210876 751.811218,730.859924 749.605408,731.397339 
	C748.257751,731.725708 746.383789,733.296204 746.207764,734.519104 
	C743.678589,752.094177 741.400024,769.705261 739.057678,787.307190 
	C736.231995,808.541626 733.406067,829.776123 730.573792,851.009705 
	C729.488220,859.148132 728.384521,867.284180 727.222229,875.917908 
	C733.920532,875.917908 739.887756,876.088013 745.828064,875.764099 
	C746.922180,875.704468 748.629822,873.752747 748.820374,872.488403 
	C749.760559,866.250183 750.392395,859.958008 750.907959,853.666504 
	C751.134338,850.903931 752.311401,849.930298 755.005493,849.989502 
	C760.164429,850.102844 765.336609,850.164551 770.486877,849.912231 
	C773.690796,849.755310 774.879700,850.858704 775.169434,854.059265 
	C775.692749,859.839294 776.534363,865.602966 777.556580,871.317322 
	C777.835144,872.874573 779.157043,875.423401 780.130188,875.485779 
	C786.165955,875.872620 792.238831,875.680481 798.676941,875.680481 
	C798.192810,871.943604 797.903076,869.161011 797.463806,866.402283 
	C793.287354,840.172974 789.117615,813.942566 784.879761,787.723145 
	C782.019531,770.026184 778.987061,752.356873 776.202637,734.648254 
	C775.768982,731.890076 774.690247,731.008972 772.029602,731.099487 
	C767.205933,731.263489 762.373474,731.171509 756.564575,731.187134 
M834.076477,860.496216 
	C834.076477,824.107483 834.076477,787.718750 834.076477,750.709473 
	C837.649353,750.709473 841.602905,750.595398 845.545959,750.748779 
	C848.280029,750.854980 849.616638,749.796204 849.614136,747.059143 
	C849.609558,741.951843 849.612915,736.844543 849.612915,731.392212 
	C831.282227,731.392212 814.019592,731.356812 796.758362,731.495178 
	C795.880310,731.502197 794.293457,732.976990 794.259460,733.817932 
	C794.034058,739.392761 794.142822,744.981018 794.142822,751.119934 
	C800.258301,751.119934 805.488586,751.119934 810.808044,751.119934 
	C810.950745,752.663391 811.108154,753.638855 811.121338,754.616333 
	C811.382751,773.913696 811.696655,793.210754 811.856262,812.509033 
	C812.017334,831.983765 812.102844,851.460144 812.026245,870.935059 
	C812.011108,874.774597 813.060547,876.366882 817.116699,876.020813 
	C821.249329,875.668152 825.442566,875.731873 829.591309,875.952209 
	C832.993530,876.132874 834.514893,875.102600 834.206604,871.476868 
	C833.925415,868.169678 834.103821,864.823486 834.076477,860.496216 
z"/>
<path fill="#46A9DB" opacity="1.000000" stroke="none" 
	d="
M640.636292,321.050171 
	C640.732361,321.201202 640.828430,321.352264 640.944824,322.155548 
	C641.331787,324.045319 641.475830,325.410950 642.101990,326.499176 
	C645.674011,332.705963 649.420532,338.812286 652.995728,345.017303 
	C659.702087,356.656860 666.331909,368.340515 672.642944,380.063263 
	C669.727722,383.172913 663.520508,385.048615 660.197693,383.442688 
	C656.315857,381.566589 653.685120,378.903290 655.343079,373.937134 
	C655.559265,373.289337 654.057678,371.333405 653.133057,371.161865 
	C648.148621,370.237091 643.102600,369.211365 638.063477,369.131866 
	C632.676208,369.046906 627.955261,368.620575 623.338257,365.022430 
	C620.804321,363.047760 618.988770,361.258392 619.620117,358.557159 
	C619.928406,357.238007 622.793091,356.516296 625.388000,355.008575 
	C624.228027,354.367554 622.876160,353.033447 622.108337,353.311615 
	C620.181396,354.009583 617.935730,355.048737 616.868835,356.650879 
	C614.509766,360.193176 612.841248,359.307190 610.972717,356.493622 
	C609.973267,354.988770 609.210327,353.294525 608.044861,351.940369 
	C606.516357,350.164337 604.695923,348.639587 602.825073,346.745972 
	C602.097717,345.661011 601.546387,344.836823 601.274902,343.755920 
	C601.004456,341.320587 600.762024,338.995300 599.814148,337.006134 
	C598.925049,335.140442 597.075623,333.755005 596.020081,331.942078 
	C591.637146,324.414368 587.481079,316.753387 583.023071,309.271667 
	C582.192078,307.877045 580.355225,307.081787 578.739624,305.906860 
	C578.047546,305.528625 577.598450,305.253479 577.149414,304.978333 
	C577.149414,304.978302 577.023987,304.963135 576.977173,304.557983 
	C570.928589,293.395325 564.979492,282.607452 558.816956,271.942902 
	C558.365784,271.162079 556.371399,271.273010 555.094482,270.969299 
	C554.759094,271.451477 554.423706,271.933655 553.487183,272.809570 
	C551.546326,275.480377 550.206665,277.757446 548.678650,280.141602 
	C548.255615,280.441315 548.113281,280.687408 547.922363,281.279877 
	C546.859680,282.718964 545.937866,283.865173 544.865479,284.673462 
	C544.606628,280.878357 544.498413,277.421143 544.360107,273.000000 
	C542.357239,273.000000 539.132385,273.002258 535.907593,272.999573 
	C528.637695,272.993469 528.805054,272.887909 527.862610,265.668488 
	C527.520569,263.048248 525.841248,258.575836 524.521240,258.482117 
	C520.203369,258.175629 517.076599,256.979553 514.875732,253.246338 
	C514.677307,252.909744 513.626953,253.075394 512.975281,252.649231 
	C513.008545,251.575638 513.038757,250.860641 513.069031,250.145630 
	C513.069031,250.145630 513.078430,250.021271 513.396484,249.819794 
	C515.040039,248.182144 516.703979,246.936142 517.635620,245.278564 
	C523.186157,235.403000 528.631226,225.466492 533.984253,215.482132 
	C537.773132,208.415131 541.360596,201.240219 545.037964,194.113495 
	C545.080200,193.919571 545.122437,193.725662 545.638794,193.203430 
	C549.369385,187.715652 552.625854,182.556198 555.940735,177.304199 
	C557.220398,178.648376 558.397644,179.884949 559.574890,181.121521 
	C559.574890,181.121521 559.786316,181.250092 559.854370,181.702606 
	C564.965027,191.291885 569.892517,200.494888 575.095825,209.539139 
	C577.958069,214.514145 581.284485,219.222015 584.401672,224.050323 
	C584.520935,224.215012 584.640198,224.379715 584.864258,225.195892 
	C587.710876,230.734085 590.445435,235.624832 593.195679,240.506836 
	C607.424072,265.764191 621.623413,291.038147 635.954895,316.236877 
	C637.025818,318.119751 639.053406,319.458496 640.636292,321.050171 
M604.209351,334.033020 
	C604.572998,334.948669 604.936584,335.864349 605.300232,336.780029 
	C605.787476,336.472656 606.274719,336.165283 606.761902,335.857880 
	C606.058533,335.069611 605.355103,334.281311 604.209351,334.033020 
M602.823608,326.330780 
	C602.851013,327.116638 602.878418,327.902466 602.905762,328.688324 
	C603.472656,328.539185 604.039551,328.390045 604.606445,328.240936 
	C604.238220,327.552216 603.869995,326.863495 602.823608,326.330780 
z"/>
<path fill="#43C1CF" opacity="1.000000" stroke="none" 
	d="
M657.309753,577.759644 
	C657.309753,577.759644 657.165771,577.374329 657.099854,576.815552 
	C654.718628,572.808777 652.403320,569.360779 650.087952,565.912781 
	C650.087952,565.912781 649.939148,565.529907 649.787842,564.949097 
	C647.574524,563.910278 645.523193,563.122375 643.448608,563.055603 
	C636.970459,562.847412 630.483521,562.911865 624.047607,562.500977 
	C624.063477,559.545715 624.023071,556.960205 624.001648,554.374573 
	C623.935364,546.392029 623.938171,546.392029 616.121521,545.996216 
	C609.105713,545.640869 608.298462,544.783508 606.773926,538.020752 
	C606.014648,534.652405 606.254944,530.464111 601.765503,529.122437 
	C594.882629,527.065491 587.873291,525.352905 581.166443,522.832153 
	C579.035828,522.031372 577.698059,519.121094 576.463623,517.115356 
	C579.246643,517.042480 581.564026,516.995911 583.880554,517.023804 
	C601.529236,517.235962 619.177795,517.462646 637.032532,517.993530 
	C638.489258,517.926514 639.739746,517.550598 641.433105,517.120117 
	C644.254639,517.101746 646.633362,517.138000 649.110229,517.542603 
	C654.654846,518.375427 660.094238,518.973083 665.550049,519.257507 
	C669.198242,519.447754 673.285400,517.406799 675.888489,521.804199 
	C681.216064,530.803711 686.479187,539.843628 691.974182,548.740112 
	C693.221375,550.759338 695.305481,552.261719 697.048706,554.196167 
	C697.093079,554.390320 697.111450,554.788330 697.083740,555.165161 
	C698.358826,557.362183 699.661682,559.182373 701.216675,561.161865 
	C701.660461,561.871338 701.852112,562.421570 701.779419,563.176514 
	C702.705994,565.238342 703.896973,567.095459 705.053162,568.937256 
	C705.018372,568.921997 705.093506,568.910583 705.062988,569.303162 
	C707.193848,575.584778 709.725220,581.228088 715.375183,584.959717 
	C715.590149,584.976929 716.005859,585.092163 716.079468,585.459412 
	C716.598206,586.281128 717.023926,587.109314 717.491333,587.133606 
	C723.516113,587.446960 729.546387,587.708862 735.576904,587.750305 
	C736.045105,587.753601 736.525391,586.007568 737.182617,585.006042 
	C737.365173,584.937439 737.753723,584.902222 738.146240,584.927612 
	C739.276733,584.367493 740.014709,583.781921 740.752686,583.196411 
	C739.809570,582.808716 738.866394,582.421021 737.694885,581.853394 
	C737.295105,581.124451 737.123718,580.575378 737.205811,579.859253 
	C737.640808,579.128967 737.822388,578.565674 738.409485,578.002747 
	C745.151672,577.975220 743.196045,583.453430 743.731323,586.464355 
	C744.332703,589.847046 744.889771,591.648071 748.572021,592.580872 
	C750.581238,593.089783 753.302490,595.734131 753.470947,597.615295 
	C753.934570,602.792053 756.716797,603.043762 760.816589,603.172119 
	C763.241150,603.248108 766.575867,604.386230 767.794006,606.195862 
	C769.918640,609.352539 772.341797,610.057678 775.707947,610.036316 
	C783.845459,609.984741 783.845886,610.060425 784.000488,617.970703 
	C784.180969,627.210876 785.755920,628.706970 794.774902,629.112488 
	C796.217590,629.177368 797.593201,630.733643 798.539551,631.700623 
	C797.082214,631.826782 796.084778,631.884521 795.088013,631.874084 
	C773.533752,631.648132 751.979919,631.293945 730.425232,631.230530 
	C718.639465,631.195862 706.844971,631.478577 695.069702,631.978333 
	C690.967163,632.152344 688.616577,630.641052 686.713928,627.227112 
	C680.816895,616.645630 674.844238,606.103088 668.693298,595.667725 
	C665.103638,589.577637 661.117554,583.721191 657.309753,577.759644 
z"/>
<path fill="#59C4E1" opacity="1.000000" stroke="none" 
	d="
M336.185028,864.912598 
	C335.846191,849.954102 335.819092,835.468384 335.100098,821.017090 
	C334.754639,814.073669 325.233398,809.113464 318.893036,812.205383 
	C317.815521,812.730835 317.211273,815.242310 317.223419,816.829895 
	C317.347931,833.142822 317.658875,849.454285 317.883911,865.766541 
	C317.945465,870.228760 317.893280,874.692505 317.893280,879.940918 
	C311.020508,879.940918 304.576874,880.112671 298.165527,879.735718 
	C297.311066,879.685486 295.978302,876.865234 295.950012,875.308105 
	C295.538483,852.668396 295.299042,830.025452 295.044250,807.382996 
	C294.793243,785.074463 294.643402,762.764404 294.267731,740.458069 
	C294.203308,736.633789 295.169250,735.080933 299.266937,735.211487 
	C312.391693,735.629395 325.548828,735.337646 338.649780,736.085083 
	C347.251587,736.575867 352.606171,742.254211 354.685608,750.350464 
	C357.822571,762.564392 357.806396,775.031494 356.223877,787.493958 
	C355.414734,793.866150 352.842712,799.259033 346.267303,802.721130 
	C355.445587,808.324097 357.046906,817.270447 357.354492,825.995056 
	C357.974823,843.592224 357.539307,861.226746 357.539307,879.318359 
	C351.621155,879.318359 344.596710,879.318359 337.122467,879.318359 
	C336.815155,874.617798 336.513794,870.008667 336.185028,864.912598 
M332.950409,791.399475 
	C333.650757,788.540833 334.800385,785.706726 334.957642,782.818481 
	C335.282043,776.859924 335.143585,770.871887 335.075714,764.897278 
	C334.943359,753.250854 331.150635,752.133301 320.323517,752.390869 
	C319.170013,752.418335 317.117615,754.690857 317.094025,755.952026 
	C316.873840,767.733826 316.962921,779.522705 317.107635,791.307739 
	C317.121033,792.397461 318.266602,794.460083 318.668671,794.407410 
	C323.285858,793.802917 327.860901,792.876160 332.950409,791.399475 
z"/>
<path fill="#59C4E1" opacity="1.000000" stroke="none" 
	d="
M656.023743,795.000000 
	C655.783813,775.529724 655.651611,756.557007 655.222046,737.591064 
	C655.126465,733.367554 656.510864,732.104614 660.623962,732.248779 
	C671.919434,732.644592 683.275146,732.112366 694.516479,733.042297 
	C706.539734,734.037048 714.914368,742.343140 716.842957,754.379944 
	C718.020264,761.727966 718.619873,769.230164 718.736206,776.674744 
	C719.053284,796.983643 719.053528,817.298828 718.997803,837.611084 
	C718.984680,842.403015 718.617126,847.255981 717.792480,851.972351 
	C715.028687,867.777588 707.563354,874.709778 691.632324,876.680603 
	C689.988159,876.883972 688.308228,876.836060 686.644775,876.838318 
	C677.010925,876.851501 667.377014,876.844788 656.756470,876.844788 
	C656.511108,849.585938 656.267700,822.542969 656.023743,795.000000 
M678.007446,787.500000 
	C678.269653,810.147217 678.576172,832.794189 678.741943,855.442078 
	C678.764343,858.508484 679.404968,859.875061 682.886902,859.876404 
	C692.908875,859.880310 696.936768,856.276978 696.989990,846.150085 
	C697.070618,830.822815 697.054810,815.493408 696.885620,800.167053 
	C696.747864,787.687988 696.502686,775.204590 695.975037,762.737061 
	C695.507385,751.683899 688.519287,746.859924 678.004395,750.133911 
	C678.004395,762.207764 678.004395,774.353882 678.007446,787.500000 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M432.583130,389.988647 
	C434.821686,385.381958 436.801910,380.627228 439.342926,376.193939 
	C451.160980,355.575409 463.126770,335.041565 475.070709,314.495453 
	C475.779175,313.276733 476.758392,312.215454 478.155426,310.357819 
	C501.247681,350.106934 523.905090,389.107605 547.040283,428.930603 
	C544.928284,429.248779 543.437988,429.663055 541.946045,429.668762 
	C526.960876,429.726288 511.971802,429.881622 496.993896,429.551605 
	C494.697998,429.501038 491.700195,427.656189 490.333099,425.719269 
	C486.896912,420.851013 484.284302,415.408630 481.166504,410.303314 
	C480.381989,409.018707 478.939117,408.136169 477.798798,407.068878 
	C476.826508,408.115265 475.711426,409.063293 474.909912,410.227295 
	C472.851288,413.216736 470.931854,416.302032 468.551819,419.077148 
	C467.773376,417.588593 467.708740,416.126953 466.972076,415.198547 
	C464.067719,411.537964 460.142456,410.383453 455.553284,410.974762 
	C452.127075,411.416199 449.794830,410.622437 449.891693,406.299896 
	C449.959473,403.276093 448.498230,401.398132 444.869354,400.797333 
	C440.942932,400.147247 437.235901,398.171906 433.438934,396.776672 
	C440.407410,389.776093 441.024048,388.293030 438.872894,385.977814 
	C436.651276,387.394501 434.617218,388.691559 432.583130,389.988647 
z"/>
<path fill="#59C4E1" opacity="1.000000" stroke="none" 
	d="
M508.094055,803.986328 
	C508.064392,819.275513 508.542908,834.092102 507.827484,848.850769 
	C507.339722,858.913391 504.701477,868.773254 495.671417,875.094604 
	C476.615692,888.434570 450.919250,878.172852 446.282379,855.320496 
	C445.131989,849.651001 444.470551,843.789368 444.410950,838.007568 
	C444.163788,814.031311 444.016571,790.049194 444.300751,766.074768 
	C444.404053,757.360962 446.424866,748.787048 452.553650,742.027771 
	C462.509399,731.047852 475.356140,731.094238 488.137695,734.406067 
	C500.822479,737.692871 505.796936,748.257568 506.772430,760.111816 
	C507.957275,774.509583 507.717163,789.024658 508.094055,803.986328 
M466.194214,789.499451 
	C466.474243,808.447632 466.695312,827.397034 467.095978,846.342712 
	C467.158203,849.285400 467.794769,852.280701 468.602173,855.127930 
	C469.867340,859.589294 473.045319,861.431580 477.696777,861.087219 
	C482.040527,860.765625 484.301270,858.291382 485.081268,854.321899 
	C485.622406,851.568054 486.062866,848.738708 486.063385,845.942993 
	C486.068054,820.660400 486.041656,795.377075 485.818115,770.095825 
	C485.781860,765.996033 484.904022,761.871460 484.106476,757.819031 
	C483.152710,752.973145 479.687958,751.527161 475.152985,751.975769 
	C470.291382,752.456604 467.447906,755.379150 467.036774,761.627014 
	C466.448700,770.564331 466.446259,779.540222 466.194214,789.499451 
z"/>
<path fill="#44B9D2" opacity="1.000000" stroke="none" 
	d="
M798.999756,631.600464 
	C797.593201,630.733643 796.217590,629.177368 794.774902,629.112488 
	C785.755920,628.706970 784.180969,627.210876 784.000488,617.970703 
	C783.845886,610.060425 783.845459,609.984741 775.707947,610.036316 
	C772.341797,610.057678 769.918640,609.352539 767.794006,606.195862 
	C766.575867,604.386230 763.241150,603.248108 760.816589,603.172119 
	C756.716797,603.043762 753.934570,602.792053 753.470947,597.615295 
	C753.302490,595.734131 750.581238,593.089783 748.572021,592.580872 
	C744.889771,591.648071 744.332703,589.847046 743.731323,586.464355 
	C743.196045,583.453430 745.151672,577.975220 738.202026,577.738953 
	C736.719666,577.320618 735.850220,577.166382 734.989868,577.005920 
	C734.999023,576.999695 734.988342,577.016418 734.992310,576.610718 
	C731.321960,568.683777 727.690430,561.140686 723.887024,553.685303 
	C723.602539,553.127686 722.088867,553.197205 720.891602,552.810425 
	C720.438843,552.090088 720.241028,551.534546 720.296021,550.763306 
	C719.295959,548.727112 718.043030,546.906616 716.562561,544.912415 
	C716.130493,544.211304 715.926025,543.683960 716.007812,542.974243 
	C715.188538,541.536743 714.082886,540.281616 712.988647,539.017090 
	C713.000000,539.007629 712.975098,539.014099 712.961182,538.710815 
	C712.610352,537.945068 712.273438,537.482544 711.699951,536.847839 
	C711.277161,536.130493 711.090942,535.585388 711.179077,534.838379 
	C711.935608,531.838257 711.471436,529.781616 707.695679,529.981812 
	C706.992371,529.543640 706.521606,529.261292 706.050903,528.978943 
	C706.050903,528.978943 706.013672,528.985535 706.016357,528.625732 
	C705.666748,527.185242 705.314392,526.104492 705.212830,524.830933 
	C704.621277,523.439148 703.778931,522.240051 702.957397,521.015747 
	C702.978271,520.990417 702.957520,521.051575 702.970825,520.663696 
	C701.967041,518.867920 700.949890,517.459900 699.936462,516.041992 
	C699.940186,516.032104 699.921326,516.063232 699.935303,515.676392 
	C699.188721,513.018433 698.916443,510.382996 697.555969,508.559509 
	C695.376221,505.637878 695.295288,500.791168 690.416077,500.174438 
	C690.416077,500.174438 690.354492,500.527740 690.188110,500.189087 
	C690.010315,499.571838 689.998840,499.293213 689.971008,498.714661 
	C689.631165,497.948303 689.307556,497.481842 688.992004,497.007690 
	C689.000000,497.000000 688.979980,497.010529 688.986084,496.626831 
	C687.979736,494.839661 686.967285,493.436157 685.719666,491.865387 
	C685.308472,491.161133 685.132385,490.624146 684.971008,489.681885 
	C685.393860,486.284210 684.394043,484.320648 680.947571,483.723236 
	C679.820679,481.675262 678.881653,479.911560 677.958740,477.739075 
	C678.290161,473.495483 676.980896,470.803558 672.873901,470.079895 
	C672.873901,470.079895 672.929382,470.008698 672.956360,469.704010 
	C672.988403,468.934357 672.993469,468.469360 673.360596,468.041138 
	C676.664612,470.139557 679.606506,472.201202 682.548340,474.262848 
	C682.907837,474.053711 683.267334,473.844543 683.626831,473.635406 
	C682.485413,471.766541 681.219543,469.959076 680.254150,468.003326 
	C679.760376,467.002869 679.804504,465.736908 679.605530,464.591003 
	C680.689392,464.583252 681.959167,464.201996 682.824768,464.632782 
	C686.453735,466.438812 689.062073,473.009247 687.628418,476.722382 
	C686.455383,479.760498 687.187195,480.942291 690.536255,481.072571 
	C693.610840,481.192169 696.653076,482.005707 699.724487,482.396606 
	C701.455322,482.616882 703.425659,483.158051 704.926758,482.593628 
	C710.906128,480.345398 720.342346,482.281464 724.222961,486.634827 
	C722.802124,488.204681 720.077942,490.014099 720.329590,491.183014 
	C720.877686,493.729309 722.941589,495.949341 725.027405,499.347717 
	C726.493164,496.215698 727.210754,494.682404 728.613647,491.684662 
	C729.744995,494.184418 730.794983,495.532532 730.911194,496.956848 
	C731.180664,500.260864 731.174988,503.613647 730.949280,506.924072 
	C730.738342,510.017761 732.032471,511.576233 734.954041,512.121094 
	C739.858459,513.035706 742.236572,508.582825 746.250183,507.147034 
	C747.041870,508.186737 747.546265,509.082245 747.725464,510.026154 
	C746.170166,514.225647 748.867432,515.764099 752.162048,517.332153 
	C757.017700,525.736389 761.801331,533.769409 766.398254,541.908020 
	C769.307495,547.058838 771.757874,552.472107 774.732239,557.582397 
	C780.683289,567.807068 786.783386,577.947021 792.961487,588.036560 
	C796.086914,593.140747 799.516418,598.058838 803.050110,603.106323 
	C803.291748,603.151611 803.783203,603.168518 803.783203,603.168518 
	C803.783203,603.168518 803.847534,603.106934 803.896851,603.367126 
	C804.076782,603.905762 804.278015,604.116882 804.549805,604.260681 
	C804.759155,604.902771 804.968506,605.544861 804.963196,606.682983 
	C805.455505,608.131775 806.162415,609.084412 806.920776,610.267334 
	C806.972290,610.497681 807.042542,610.964478 807.033264,611.314209 
	C807.650818,612.451904 808.277710,613.239868 809.156433,614.103333 
	C809.747375,614.252441 810.086426,614.325928 810.425537,614.399414 
	C811.113098,615.788879 811.800720,617.178284 812.703247,619.183777 
	C814.617981,623.287048 816.317749,626.774231 818.670227,631.600464 
	C811.491333,631.600464 805.245544,631.600464 798.999756,631.600464 
z"/>
<path fill="#5AC4E1" opacity="1.000000" stroke="none" 
	d="
M283.087189,863.750000 
	C283.987946,869.387512 284.834778,874.593018 285.769409,880.338257 
	C278.651978,880.338257 272.124969,880.338257 265.176697,880.338257 
	C264.153412,873.287781 262.939667,866.270447 262.197968,859.203491 
	C261.865356,856.034180 260.772675,854.821106 257.580017,854.940369 
	C252.425232,855.132874 247.257355,855.072327 242.097244,854.985840 
	C239.498886,854.942322 238.144272,855.746826 237.915161,858.602600 
	C237.411133,864.885010 236.723328,871.159424 235.841003,877.399048 
	C235.661926,878.665466 234.340912,880.742065 233.468399,880.782959 
	C227.213058,881.076172 220.937607,880.939697 214.185806,880.939697 
	C220.671509,832.460571 227.067719,784.650330 233.547867,736.212646 
	C242.545074,736.212646 251.337402,736.033691 260.105011,736.418274 
	C261.327789,736.471924 263.170410,739.166870 263.468689,740.860535 
	C265.771881,753.937683 267.763153,767.069763 269.855133,780.184204 
	C272.653564,797.727600 275.465973,815.268738 278.252197,832.814087 
	C279.866333,842.978821 281.440887,853.149841 283.087189,863.750000 
M256.767334,819.827515 
	C254.450760,804.603088 252.134186,789.378723 249.817612,774.154297 
	C249.043427,774.193176 248.269241,774.231995 247.495056,774.270813 
	C245.154968,794.983215 242.814896,815.695679 240.430847,836.797241 
	C247.150436,836.797241 252.847427,836.797241 258.959747,836.797241 
	C258.234619,831.146484 257.564362,825.923279 256.767334,819.827515 
z"/>
<path fill="#5AC4E0" opacity="1.000000" stroke="none" 
	d="
M368.056122,858.068237 
	C370.739777,837.879272 373.412415,818.136536 376.067596,798.391357 
	C378.725159,778.628296 381.428131,758.870850 383.946899,739.090027 
	C384.335388,736.039185 385.676880,735.184021 388.481079,735.218567 
	C394.976929,735.298706 401.501617,735.541870 407.963470,735.048828 
	C412.763519,734.682617 414.227692,736.590149 414.859924,740.993652 
	C416.960815,755.626099 419.420197,770.207031 421.741028,784.807922 
	C426.617004,815.483704 431.493500,846.159485 436.359253,876.836853 
	C436.458252,877.461182 436.428864,878.105835 436.507721,879.748474 
	C430.215271,879.748474 424.139740,879.868469 418.081177,879.601929 
	C417.293518,879.567261 416.140320,877.620911 415.928894,876.423706 
	C414.831848,870.210999 413.846008,863.970154 413.117035,857.704712 
	C412.804565,855.019348 411.855194,853.876099 409.087189,853.953613 
	C403.759827,854.102844 398.422150,854.159851 393.096771,853.995239 
	C390.020905,853.900208 389.098572,855.232849 388.876556,858.094666 
	C388.401428,864.218994 387.730804,870.335938 386.857117,876.415161 
	C386.676147,877.674377 385.309784,879.722839 384.412537,879.765015 
	C378.159637,880.058960 371.886383,879.920349 365.293945,879.920349 
	C366.268982,872.312378 367.153229,865.412903 368.056122,858.068237 
M392.944763,836.363037 
	C398.516449,836.363037 404.088104,836.363037 410.196686,836.363037 
	C406.732544,812.142639 403.436279,789.096008 400.140015,766.049377 
	C397.292267,783.812073 395.124603,801.445190 393.179932,819.102844 
	C392.556824,824.760742 392.523346,830.483643 392.944763,836.363037 
z"/>
<path fill="#5AC4E1" opacity="1.000000" stroke="none" 
	d="
M868.772583,864.711426 
	C866.902710,874.972534 866.902710,874.973022 856.752319,874.979065 
	C853.462463,874.981018 850.172607,874.979431 846.278503,874.979431 
	C848.106934,860.921326 849.815613,847.469849 851.614014,834.030334 
	C854.389221,813.291077 857.248901,792.563049 860.027832,771.824219 
	C861.746643,758.997192 863.300903,746.146912 865.191162,733.345825 
	C865.364563,732.171143 867.412659,730.440979 868.647400,730.391296 
	C876.295776,730.083923 883.963867,730.305542 891.618103,730.089661 
	C894.815918,729.999512 895.027466,732.059387 895.378906,734.207947 
	C897.872437,749.448792 900.377197,764.687927 902.814880,779.937683 
	C906.064514,800.266724 909.246277,820.606628 912.478149,840.938538 
	C914.067871,850.939453 915.796936,860.919983 917.192871,870.947327 
	C917.350037,872.076660 915.805237,874.495239 914.950439,874.543152 
	C909.176880,874.866333 903.376526,874.711792 897.294922,874.711792 
	C896.000488,866.115601 894.770203,857.945435 893.439209,849.106445 
	C886.468994,849.106445 879.375671,849.021423 872.290771,849.227234 
	C871.512878,849.249878 870.273254,850.864685 870.120911,851.872925 
	C869.501404,855.973816 869.222168,860.126282 868.772583,864.711426 
M877.292175,785.872437 
	C875.723755,800.791687 874.155273,815.710938 872.564331,830.843811 
	C879.239502,830.843811 884.812134,830.843811 891.124023,830.843811 
	C887.760986,807.331726 884.457825,784.237793 881.154602,761.143799 
	C880.882690,761.189514 880.610779,761.235291 880.338867,761.281006 
	C879.401184,769.189209 878.463562,777.097412 877.292175,785.872437 
z"/>
<path fill="#5AC4E0" opacity="1.000000" stroke="none" 
	d="
M757.054626,731.186768 
	C762.373474,731.171509 767.205933,731.263489 772.029602,731.099487 
	C774.690247,731.008972 775.768982,731.890076 776.202637,734.648254 
	C778.987061,752.356873 782.019531,770.026184 784.879761,787.723145 
	C789.117615,813.942566 793.287354,840.172974 797.463806,866.402283 
	C797.903076,869.161011 798.192810,871.943604 798.676941,875.680481 
	C792.238831,875.680481 786.165955,875.872620 780.130188,875.485779 
	C779.157043,875.423401 777.835144,872.874573 777.556580,871.317322 
	C776.534363,865.602966 775.692749,859.839294 775.169434,854.059265 
	C774.879700,850.858704 773.690796,849.755310 770.486877,849.912231 
	C765.336609,850.164551 760.164429,850.102844 755.005493,849.989502 
	C752.311401,849.930298 751.134338,850.903931 750.907959,853.666504 
	C750.392395,859.958008 749.760559,866.250183 748.820374,872.488403 
	C748.629822,873.752747 746.922180,875.704468 745.828064,875.764099 
	C739.887756,876.088013 733.920532,875.917908 727.222229,875.917908 
	C728.384521,867.284180 729.488220,859.148132 730.573792,851.009705 
	C733.406067,829.776123 736.231995,808.541626 739.057678,787.307190 
	C741.400024,769.705261 743.678589,752.094177 746.207764,734.519104 
	C746.383789,733.296204 748.257751,731.725708 749.605408,731.397339 
	C751.811218,730.859924 754.233521,731.210876 757.054626,731.186768 
M769.658813,813.905457 
	C767.149170,796.649841 764.639587,779.394226 762.130005,762.138611 
	C761.841919,762.180786 761.553833,762.223022 761.265747,762.265198 
	C758.647705,785.341248 756.029602,808.417236 753.373657,831.827332 
	C759.644775,831.827332 765.527527,831.827332 771.976868,831.827332 
	C771.214172,825.877747 770.503235,820.331665 769.658813,813.905457 
z"/>
<path fill="#45B1D7" opacity="1.000000" stroke="none" 
	d="
M745.962891,507.002808 
	C742.236572,508.582825 739.858459,513.035706 734.954041,512.121094 
	C732.032471,511.576233 730.738342,510.017761 730.949280,506.924072 
	C731.174988,503.613647 731.180664,500.260864 730.911194,496.956848 
	C730.794983,495.532532 729.744995,494.184418 728.613647,491.684662 
	C727.210754,494.682404 726.493164,496.215698 725.027405,499.347717 
	C722.941589,495.949341 720.877686,493.729309 720.329590,491.183014 
	C720.077942,490.014099 722.802124,488.204681 724.222961,486.634827 
	C720.342346,482.281464 710.906128,480.345398 704.926758,482.593628 
	C703.425659,483.158051 701.455322,482.616882 699.724487,482.396606 
	C696.653076,482.005707 693.610840,481.192169 690.536255,481.072571 
	C687.187195,480.942291 686.455383,479.760498 687.628418,476.722382 
	C689.062073,473.009247 686.453735,466.438812 682.824768,464.632782 
	C681.959167,464.201996 680.689392,464.583252 679.605530,464.591003 
	C679.804504,465.736908 679.760376,467.002869 680.254150,468.003326 
	C681.219543,469.959076 682.485413,471.766541 683.626831,473.635406 
	C683.267334,473.844543 682.907837,474.053711 682.548340,474.262848 
	C679.606506,472.201202 676.664612,470.139557 673.109314,468.040222 
	C672.328369,468.004425 672.160767,468.006317 671.750244,467.833923 
	C670.804504,467.006775 670.101746,466.353851 669.399048,465.700928 
	C669.399048,465.700928 669.233582,465.325836 669.164734,464.815979 
	C669.086853,463.865906 669.077820,463.425690 669.277222,462.875031 
	C669.742249,462.564087 669.918091,462.308075 670.360352,461.940063 
	C671.757263,459.570648 672.807007,457.257751 673.856750,454.944855 
	C673.308716,454.685333 672.760620,454.425812 672.212585,454.166290 
	C671.443298,455.009674 670.674072,455.853058 670.623413,455.908630 
	C668.592529,455.511383 667.294800,455.257538 665.712036,455.003723 
	C665.236938,455.005371 665.046936,455.006958 664.812744,454.863708 
	C664.768433,454.718872 664.827515,454.421814 664.873840,454.023193 
	C664.238953,452.788147 663.557678,451.951782 662.916748,451.077942 
	C662.956970,451.040527 662.884033,451.108032 662.885498,450.778351 
	C662.267273,449.970337 661.647583,449.491974 660.784180,448.842224 
	C659.858765,448.000763 659.176941,447.330658 658.495056,446.660583 
	C658.495056,446.660583 658.318848,446.267609 658.174438,445.845459 
	C657.899475,445.121796 657.687317,444.897308 657.393677,444.749878 
	C657.393677,444.749878 657.254944,444.333313 657.180420,443.801880 
	C657.071716,442.829987 657.037598,442.389557 657.001343,441.570740 
	C656.296265,440.144714 655.593384,439.097076 654.626831,438.003143 
	C654.015259,437.882843 653.667297,437.808838 653.319397,437.734833 
	C653.011719,436.976318 652.703979,436.217804 652.188354,434.888245 
	C651.309082,432.847626 650.637817,431.378052 649.894653,429.737152 
	C649.691833,429.346802 649.509155,429.186707 649.151123,428.748230 
	C648.021790,426.604858 647.016174,424.798889 646.239258,422.815918 
	C645.943054,422.114532 645.418152,421.590149 644.632874,421.016541 
	C644.031372,420.882843 643.690247,420.798340 643.349121,420.713837 
	C643.349121,420.713837 643.196411,420.340027 643.119751,419.809265 
	C642.705811,418.406769 642.368530,417.535004 641.964478,416.327301 
	C637.480713,408.627167 633.063782,401.262970 628.646851,393.898743 
	C628.646851,393.898743 628.291077,393.611572 628.160034,393.109955 
	C627.220093,391.409515 626.411133,390.210693 625.602173,389.011871 
	C625.379395,388.643433 625.156555,388.274994 624.924927,387.269440 
	C624.432129,386.095947 623.948242,385.559570 623.464294,385.023193 
	C623.335693,384.885132 623.207031,384.747070 623.007690,384.001862 
	C622.157593,382.228882 621.378174,381.063080 620.598694,379.897278 
	C620.598694,379.897278 620.258728,379.680359 620.128174,379.170837 
	C618.700378,375.499908 617.703308,372.170441 616.046875,369.209961 
	C611.856201,361.720551 607.369263,354.396851 603.001038,347.006775 
	C604.695923,348.639587 606.516357,350.164337 608.044861,351.940369 
	C609.210327,353.294525 609.973267,354.988770 610.972717,356.493622 
	C612.841248,359.307190 614.509766,360.193176 616.868835,356.650879 
	C617.935730,355.048737 620.181396,354.009583 622.108337,353.311615 
	C622.876160,353.033447 624.228027,354.367554 625.388000,355.008575 
	C622.793091,356.516296 619.928406,357.238007 619.620117,358.557159 
	C618.988770,361.258392 620.804321,363.047760 623.338257,365.022430 
	C627.955261,368.620575 632.676208,369.046906 638.063477,369.131866 
	C643.102600,369.211365 648.148621,370.237091 653.133057,371.161865 
	C654.057678,371.333405 655.559265,373.289337 655.343079,373.937134 
	C653.685120,378.903290 656.315857,381.566589 660.197693,383.442688 
	C663.520508,385.048615 669.727722,383.172913 672.715820,380.402313 
	C676.896606,386.815979 680.799316,392.864594 684.377625,399.099304 
	C689.976624,408.854584 695.359985,418.733673 700.827515,428.564270 
	C705.283508,436.576141 709.631958,444.649628 714.203491,452.594910 
	C720.047485,462.751709 726.071655,472.804749 732.010254,482.907166 
	C735.415894,488.700714 738.816956,494.497101 742.197205,500.305481 
	C743.485291,502.518799 744.709534,504.769257 745.962891,507.002808 
M659.586121,443.516754 
	C659.586121,443.516754 659.476807,443.581451 659.586121,443.516754 
z"/>
<path fill="#45B2D7" opacity="1.000000" stroke="none" 
	d="
M512.972290,253.007812 
	C513.626953,253.075394 514.677307,252.909744 514.875732,253.246338 
	C517.076599,256.979553 520.203369,258.175629 524.521240,258.482117 
	C525.841248,258.575836 527.520569,263.048248 527.862610,265.668488 
	C528.805054,272.887909 528.637695,272.993469 535.907593,272.999573 
	C539.132385,273.002258 542.357239,273.000000 544.360107,273.000000 
	C544.498413,277.421143 544.606628,280.878357 544.854004,285.065979 
	C542.794678,294.187164 548.630127,299.284210 552.879089,305.031647 
	C552.879089,305.031647 552.976379,305.036987 552.988037,305.444763 
	C555.231323,310.020966 557.308472,314.281586 559.730164,318.336456 
	C563.484253,324.622192 567.473328,330.767578 571.362732,336.972504 
	C571.605530,337.318207 571.848267,337.663910 572.095215,338.717712 
	C574.864868,344.281067 577.539978,349.190826 580.422485,353.975677 
	C583.094543,358.411346 585.988281,362.713501 588.784485,367.074432 
	C588.784485,367.074432 588.932922,367.061371 588.958008,367.476624 
	C590.061829,369.589386 591.140625,371.286926 592.219482,372.984467 
	C592.395325,373.139008 592.571106,373.293549 592.870728,374.184570 
	C592.999573,376.279633 593.004639,377.638245 592.950562,379.347107 
	C591.466675,381.567261 590.042053,383.437134 588.617432,385.307007 
	C587.979858,385.203125 587.342285,385.099243 586.704712,384.995361 
	C590.206543,377.304688 583.808167,373.524200 582.527832,369.331085 
	C578.844604,369.680756 576.397827,369.979858 573.942017,370.121307 
	C572.453369,370.207031 570.950928,370.011353 569.454468,370.006073 
	C560.230164,369.973572 560.033813,369.779877 559.998779,360.426788 
	C559.994385,359.260986 560.274536,357.977478 559.877502,356.961639 
	C559.456177,355.883484 558.559875,354.549377 557.568115,354.208801 
	C551.945679,352.277924 545.190552,354.639618 541.294739,359.531708 
	C541.044250,359.846222 540.169861,359.663879 539.431030,359.725555 
	C541.967651,352.619629 541.880066,352.502441 534.049133,351.998199 
	C529.001160,351.673157 525.893127,347.368530 527.246582,342.576782 
	C528.485840,338.189453 527.975525,337.689636 523.405579,338.730804 
	C522.600708,338.914154 521.759888,338.939484 520.631104,338.873474 
	C519.510010,337.500793 518.579590,336.353241 517.896240,335.073761 
	C514.495605,328.706543 511.392120,322.168304 507.742188,315.949646 
	C503.295471,308.373505 498.570221,300.945953 493.634918,293.678589 
	C491.553009,290.612854 492.148376,288.387177 493.864746,285.536560 
	C500.350922,274.764099 506.621185,263.861633 512.972290,253.007812 
z"/>
<path fill="#41D2C6" opacity="1.000000" stroke="none" 
	d="
M480.982788,571.993591 
	C480.982788,571.993591 481.121643,571.601685 481.512390,571.310181 
	C484.263855,566.677368 486.624573,562.335999 488.985291,557.994690 
	C488.985260,557.994690 489.132751,557.607849 489.520203,557.310486 
	C492.296265,552.698120 494.684906,548.383118 497.073517,544.068115 
	C497.073517,544.068115 497.193420,543.663818 497.514404,543.416382 
	C498.185730,543.096985 498.536041,543.025085 499.229645,542.941162 
	C499.968567,541.908203 500.364197,540.887268 500.807800,539.540527 
	C501.443390,538.415100 502.030975,537.615540 502.998169,536.827271 
	C505.063965,536.071228 507.589111,534.203064 508.285950,534.734131 
	C510.817047,536.662903 510.485779,539.272705 508.076385,541.471069 
	C507.836548,541.689880 507.483948,542.018616 507.512207,542.257507 
	C507.635986,543.302979 507.880798,544.334167 508.081665,545.370544 
	C509.027405,545.146973 510.042816,545.067993 510.897491,544.655762 
	C511.625580,544.304626 512.168640,543.569946 513.903320,542.001709 
	C513.387878,545.700867 513.184387,548.053711 512.716431,550.352661 
	C511.318176,557.221313 513.461121,559.892395 520.346863,560.024841 
	C526.857666,560.150024 533.622620,559.721191 537.970825,566.284302 
	C537.570557,567.035156 537.274170,567.520813 536.759766,568.122192 
	C536.272217,568.454529 536.107361,568.733093 536.058716,569.040283 
	C536.070190,569.006775 536.095337,569.072998 535.786377,569.078979 
	C535.362183,569.744141 535.246948,570.403259 535.087524,571.359253 
	C534.713745,572.134644 534.384155,572.613159 533.841431,572.823730 
	C529.450012,572.807983 531.605347,576.071594 531.018433,578.016113 
	C531.013123,577.998413 531.036438,578.032959 530.786011,577.936096 
	C530.368774,578.576355 530.201843,579.313538 529.887695,580.390991 
	C529.509338,582.429260 529.278198,584.127319 529.090454,586.217285 
	C533.177307,590.762207 538.360840,588.314880 543.054688,589.393799 
	C544.089783,590.514954 545.048035,591.838989 546.059692,591.881165 
	C551.626831,592.113342 557.207397,592.000854 562.782104,592.089478 
	C563.703857,592.104065 564.619141,592.528137 566.311279,592.960876 
	C555.621460,596.458740 565.466614,602.213501 562.644470,606.470886 
	C566.132263,607.086243 568.704834,607.830994 571.302856,607.933960 
	C575.820801,608.112976 577.818909,610.202026 578.054077,614.708984 
	C578.475159,622.776245 578.821167,623.090088 586.866089,622.942200 
	C590.313171,622.878845 593.037964,623.519165 594.824707,626.770813 
	C595.700439,628.364563 597.004700,629.722717 597.760193,631.408569 
	C589.930054,631.671753 582.454834,631.713867 574.891602,631.416138 
	C572.683472,629.706421 570.614502,628.763184 568.551514,631.805176 
	C554.720520,631.742798 541.357910,631.645691 528.008057,631.235840 
	C530.163269,626.719604 528.771729,624.280273 523.995789,624.021057 
	C521.839905,623.903992 519.606995,624.308289 517.533997,623.876343 
	C516.147034,623.587402 514.349792,622.521729 513.819519,621.323975 
	C510.915436,614.765015 506.513855,610.057983 499.500275,607.900146 
	C498.496552,607.591431 497.262909,606.225098 497.126007,605.214050 
	C496.508179,600.651489 493.735107,599.302368 489.741333,600.061951 
	C486.623474,600.655029 483.611389,601.804199 479.993073,602.871155 
	C479.898987,602.137695 479.382019,600.607727 479.578644,599.175659 
	C480.182983,594.774353 481.273132,590.430908 481.696808,586.019165 
	C481.817047,584.767090 480.304138,583.358276 479.670166,582.248657 
	C476.450592,585.785522 473.630615,588.883362 470.863892,591.624207 
	C471.588745,589.497009 472.260315,587.726746 472.931885,585.956482 
	C472.931885,585.956482 473.069733,585.565369 473.465698,585.269958 
	C476.235382,580.647583 478.609070,576.320557 480.982788,571.993591 
z"/>
<path fill="#42C8CB" opacity="1.000000" stroke="none" 
	d="
M538.074585,566.019043 
	C533.622620,559.721191 526.857666,560.150024 520.346863,560.024841 
	C513.461121,559.892395 511.318176,557.221313 512.716431,550.352661 
	C513.184387,548.053711 513.387878,545.700867 513.903320,542.001709 
	C512.168640,543.569946 511.625580,544.304626 510.897491,544.655762 
	C510.042816,545.067993 509.027405,545.146973 508.081665,545.370544 
	C507.880798,544.334167 507.635986,543.302979 507.512207,542.257507 
	C507.483948,542.018616 507.836548,541.689880 508.076385,541.471069 
	C510.485779,539.272705 510.817047,536.662903 508.285950,534.734131 
	C507.589111,534.203064 505.063965,536.071228 503.093262,536.476807 
	C503.530701,534.071289 504.252625,532.027527 504.974548,529.983826 
	C504.974518,529.983826 505.131195,529.607666 505.527740,529.314026 
	C507.820740,526.306213 509.717163,523.592102 511.968567,520.806152 
	C512.881348,519.561340 513.439209,518.388306 514.461853,517.153076 
	C535.283630,517.119202 555.640625,517.147644 575.997559,517.176086 
	C577.698059,519.121094 579.035828,522.031372 581.166443,522.832153 
	C587.873291,525.352905 594.882629,527.065491 601.765503,529.122437 
	C606.254944,530.464111 606.014648,534.652405 606.773926,538.020752 
	C608.298462,544.783508 609.105713,545.640869 616.121521,545.996216 
	C623.938171,546.392029 623.935364,546.392029 624.001648,554.374573 
	C624.023071,556.960205 624.063477,559.545715 623.581177,562.553101 
	C597.365601,562.985901 571.663452,562.909851 545.963989,563.139526 
	C543.325867,563.163147 540.703979,565.015320 538.074585,566.019043 
z"/>
<path fill="#41DCC1" opacity="1.000000" stroke="none" 
	d="
M446.297546,591.179504 
	C446.094604,591.712830 445.891693,592.246216 445.175873,592.912659 
	C444.359314,593.131531 444.055634,593.217285 443.751953,593.303040 
	C444.099762,593.533447 444.447571,593.763794 444.795349,593.994202 
	C444.427429,594.958557 444.059479,595.922913 443.190918,596.752197 
	C441.505432,598.879883 440.320618,601.142761 439.135803,603.405640 
	C434.137024,612.542114 429.244385,621.740295 424.010010,630.739746 
	C423.328094,631.912292 420.889038,632.602539 419.261688,632.606445 
	C378.170837,632.706177 337.079712,632.689209 295.988647,632.654846 
	C294.914825,632.653992 293.841370,632.230042 291.937744,631.828186 
	C295.909424,624.923462 299.575836,618.533020 303.257751,612.151489 
	C315.709137,590.570862 328.154633,568.986755 340.641144,547.426453 
	C341.703857,545.591492 343.054382,543.923157 344.919678,541.931763 
	C345.993988,540.623474 346.419891,539.560852 346.845825,538.498230 
	C347.179565,537.452271 347.513336,536.406250 348.410370,535.193359 
	C349.319122,535.013672 349.664642,535.000854 350.377197,535.067261 
	C351.101196,535.469238 351.765961,535.788574 351.769501,536.115112 
	C351.836060,542.234314 354.975555,544.745056 360.985138,545.333252 
	C365.006958,545.726929 370.722534,544.859680 370.853790,551.942749 
	C370.867035,552.658325 373.449402,553.129456 374.521454,554.074768 
	C375.715515,555.127686 377.619843,556.901489 377.354126,557.827576 
	C375.977570,562.625549 378.335205,564.887329 381.768158,566.326233 
	C383.530731,564.447632 385.149841,562.721863 386.768951,560.996155 
	C383.731689,566.826233 380.634308,572.626343 377.695038,578.505371 
	C376.851746,580.192078 376.465149,582.107178 375.869141,583.917542 
	C377.701569,584.322510 379.522003,584.796082 381.368530,585.121155 
	C391.538788,586.911499 398.953766,583.834351 402.780548,573.560852 
	C403.906281,570.538635 406.242218,567.967224 408.687744,565.034729 
	C410.791290,563.279297 412.230560,561.678467 413.669830,560.077637 
	C413.776001,560.501953 413.882172,560.926270 413.988342,561.350586 
	C415.153717,561.409668 416.318726,561.476746 417.484497,561.525696 
	C418.743896,561.578613 420.072876,561.369751 421.251587,561.701233 
	C425.686096,562.948547 425.915833,566.424011 426.188995,570.332825 
	C426.324463,572.271301 428.441223,574.071350 429.661621,575.934021 
	C430.794708,574.480591 432.397614,573.188293 432.962524,571.540100 
	C434.122650,568.155090 435.416931,566.850281 438.404633,569.840881 
	C439.210876,570.648010 440.370697,571.099243 441.188660,571.897766 
	C446.601410,577.181702 446.580902,577.194092 451.321442,571.563538 
	C451.467896,571.389648 451.936279,571.486755 452.255096,571.463135 
	C454.369659,576.311707 452.925934,578.286072 446.763031,578.980286 
	C440.533722,579.681946 439.565094,581.439453 441.991699,587.253601 
	C443.332397,586.486328 444.708832,585.698486 445.883514,585.026184 
	C446.051422,587.521912 446.174500,589.350708 446.297546,591.179504 
M403.497986,629.002441 
	C408.160950,629.002197 412.838684,629.214294 417.479279,628.891846 
	C419.213135,628.771423 421.796021,627.773315 422.295593,626.506470 
	C422.843292,625.117798 421.432404,622.956604 420.875031,621.132080 
	C420.412628,621.248413 419.950226,621.364685 419.487823,621.481018 
	C419.487823,623.132568 419.487823,624.784119 419.487823,626.418274 
	C415.294312,626.918579 414.319641,626.408691 414.093201,622.945801 
	C413.955353,620.837585 414.755096,618.662354 414.577423,616.564331 
	C414.449066,615.048889 413.627197,612.493164 412.734497,612.315979 
	C405.841125,610.947388 398.882843,609.720459 391.887177,609.121338 
	C387.674988,608.760559 385.942474,607.285950 385.923157,603.052795 
	C385.907379,599.593567 386.432007,596.337952 383.576294,592.859375 
	C380.958160,589.670166 379.128845,587.262207 374.773254,587.824951 
	C374.232483,587.894775 373.367798,586.690002 372.904083,585.924011 
	C369.918396,580.991516 365.563049,577.465149 359.939941,577.229858 
	C354.713226,577.011292 353.663422,574.639160 354.007996,570.375366 
	C354.373505,565.852478 353.533264,561.845825 350.414124,559.602722 
	C345.591156,560.100525 341.665344,560.505737 338.074554,560.876343 
	C336.733643,562.592590 335.303253,564.294006 334.015717,566.097534 
	C331.108063,570.170349 328.090149,574.181335 325.455414,578.427490 
	C322.738159,582.806641 320.545288,587.508179 317.905609,591.939148 
	C311.426910,602.814026 304.658478,613.519592 298.342346,624.486572 
	C295.390381,629.612366 296.418213,631.185913 302.112854,630.952454 
	C328.903717,629.854492 355.776703,633.242859 382.537537,629.155884 
	C389.047302,628.161682 395.837585,629.004333 403.497986,629.002441 
M373.642242,578.433960 
	C373.127747,578.165955 372.613251,577.897949 372.098816,577.629944 
	C371.876312,578.165527 371.653809,578.701111 371.431274,579.236694 
	C372.078278,579.144287 372.725281,579.051941 373.642242,578.433960 
z"/>
<path fill="#5AC4E1" opacity="1.000000" stroke="none" 
	d="
M834.078064,860.995361 
	C834.103821,864.823486 833.925415,868.169678 834.206604,871.476868 
	C834.514893,875.102600 832.993530,876.132874 829.591309,875.952209 
	C825.442566,875.731873 821.249329,875.668152 817.116699,876.020813 
	C813.060547,876.366882 812.011108,874.774597 812.026245,870.935059 
	C812.102844,851.460144 812.017334,831.983765 811.856262,812.509033 
	C811.696655,793.210754 811.382751,773.913696 811.121338,754.616333 
	C811.108154,753.638855 810.950745,752.663391 810.808044,751.119934 
	C805.488586,751.119934 800.258301,751.119934 794.142822,751.119934 
	C794.142822,744.981018 794.034058,739.392761 794.259460,733.817932 
	C794.293457,732.976990 795.880310,731.502197 796.758362,731.495178 
	C814.019592,731.356812 831.282227,731.392212 849.612915,731.392212 
	C849.612915,736.844543 849.609558,741.951843 849.614136,747.059143 
	C849.616638,749.796204 848.280029,750.854980 845.545959,750.748779 
	C841.602905,750.595398 837.649353,750.709473 834.076477,750.709473 
	C834.076477,787.718750 834.076477,824.107483 834.078064,860.995361 
z"/>
<path fill="#5CC4E0" opacity="1.000000" stroke="none" 
	d="
M524.200562,823.013855 
	C524.140503,813.319275 524.080444,803.624695 524.063538,792.995117 
	C524.086060,774.273499 524.009521,756.486572 524.143799,738.701172 
	C524.155396,737.160278 525.478210,734.353149 526.329224,734.299194 
	C532.387817,733.915222 538.482056,734.094543 544.471130,734.094543 
	C544.960083,738.191040 545.365356,741.586670 545.770630,744.982300 
	C545.853394,758.006653 545.936157,771.030945 545.961670,784.989929 
	C545.944031,808.860474 545.948425,831.796631 546.108337,854.731689 
	C546.118286,856.154175 547.351440,857.568176 548.015442,858.986084 
	C549.393066,858.981201 550.770691,858.976318 553.029297,859.023193 
	C560.286987,859.025208 566.663757,858.975525 573.040466,858.925903 
	C573.040466,858.925903 573.479065,858.984802 573.704285,859.515381 
	C573.956421,864.010498 573.700684,868.002686 574.085815,871.932068 
	C574.511841,876.279419 572.655762,877.100464 568.727112,877.100525 
	C559.836670,877.100708 550.946228,877.618652 542.055786,877.926086 
	C541.277771,877.929688 540.499817,877.933350 538.875610,877.887573 
	C533.771118,877.827026 529.512817,877.815918 525.254517,877.804810 
	C525.254517,877.804810 525.046631,877.442383 525.047974,876.830200 
	C524.980957,875.116516 524.912720,874.014954 524.844482,872.913452 
	C524.836731,871.590454 524.829041,870.267517 524.906616,868.073181 
	C524.986084,853.736084 525.000793,840.270264 524.940735,826.804749 
	C524.935120,825.540039 524.458496,824.277466 524.200562,823.013855 
z"/>
<path fill="#42C8CB" opacity="1.000000" stroke="none" 
	d="
M432.345734,390.176147 
	C434.617218,388.691559 436.651276,387.394501 438.872894,385.977814 
	C441.024048,388.293030 440.407410,389.776093 433.438934,396.776672 
	C437.235901,398.171906 440.942932,400.147247 444.869354,400.797333 
	C448.498230,401.398132 449.959473,403.276093 449.891693,406.299896 
	C449.794830,410.622437 452.127075,411.416199 455.553284,410.974762 
	C460.142456,410.383453 464.067719,411.537964 466.972076,415.198547 
	C467.708740,416.126953 467.773376,417.588593 468.242157,419.242767 
	C467.788055,421.226379 467.465942,422.904785 466.654510,424.298218 
	C457.474365,440.062561 448.219269,455.783264 438.456085,471.846375 
	C437.634766,472.830872 437.341644,473.484619 437.048523,474.138336 
	C436.710297,474.948639 436.372101,475.758911 435.489685,476.873901 
	C434.305908,478.467926 433.666321,479.757172 433.026733,481.046448 
	C433.026733,481.046448 432.997955,481.002502 432.609741,480.997559 
	C429.781250,480.721954 427.226440,479.853699 424.922668,480.295288 
	C418.583435,481.510345 418.580444,481.736481 417.286469,475.321533 
	C416.711517,472.471313 417.319031,468.504395 415.638489,466.997528 
	C413.888306,465.428223 410.024628,466.237762 407.089142,465.963318 
	C403.086273,465.589081 400.091949,464.434082 400.896210,459.326355 
	C401.143616,457.755005 400.178101,455.992676 399.791107,454.412109 
	C397.728119,454.624451 396.263550,454.775208 394.798981,454.925964 
	C395.179047,454.100769 395.559082,453.275543 396.496552,452.130188 
	C398.330292,449.434601 399.606628,447.059113 400.882935,444.683655 
	C405.259583,436.588165 409.542328,428.440063 414.037872,420.411133 
	C419.121307,411.332214 424.375031,402.348175 429.613892,393.357361 
	C430.258698,392.250702 431.267975,391.356354 432.345734,390.176147 
z"/>
<path fill="#41C9CA" opacity="1.000000" stroke="none" 
	d="
M449.071716,494.245148 
	C449.138214,494.029205 449.204681,493.813263 449.760406,493.242981 
	C457.815155,480.173279 465.514465,467.534180 472.839050,454.681519 
	C474.401398,451.940002 476.011658,450.683411 479.212708,451.250793 
	C483.090240,455.759216 487.792938,456.691284 493.101807,456.071686 
	C494.116974,455.953217 496.060669,456.525726 496.177185,457.075775 
	C497.641602,463.988342 503.855499,461.242737 507.795074,463.049622 
	C509.550201,463.854614 512.260864,465.098511 512.430481,466.414764 
	C513.303772,473.191559 517.957764,473.828339 523.205078,474.010040 
	C527.278015,474.151093 529.809143,476.740234 528.715942,480.776794 
	C527.641113,484.745544 528.868713,486.008240 532.468201,486.292023 
	C534.603638,486.460358 536.796326,487.216034 538.845581,486.912659 
	C544.555481,486.067505 547.701599,488.076691 547.579224,494.009399 
	C534.234558,494.226807 521.304565,494.790405 508.395447,494.481018 
	C502.466064,494.338898 497.160919,497.991028 497.063354,504.064575 
	C497.063354,504.064575 496.999603,504.030914 496.717468,504.167236 
	C495.567169,505.662811 494.691681,507.017517 493.832092,508.382202 
	C490.556152,513.583191 487.285339,518.787476 484.013184,523.619080 
	C484.541534,521.351379 485.069244,519.455139 485.596985,517.558960 
	C485.212646,517.376343 484.828308,517.193787 484.444000,517.011169 
	C482.230377,520.022827 480.016785,523.034424 477.242767,526.808533 
	C476.912750,526.827759 475.456360,526.912659 474.000000,526.574768 
	C474.302368,512.449524 465.305328,510.479645 457.600037,513.000000 
	C456.133453,512.999146 455.066864,512.998352 453.958374,512.630310 
	C451.759521,510.582581 453.417664,505.908661 449.000641,505.610413 
	C448.000946,504.139465 447.003082,503.058167 446.044495,501.623566 
	C447.079773,498.928558 448.075714,496.586853 449.071716,494.245148 
z"/>
<path fill="#41D3C6" opacity="1.000000" stroke="none" 
	d="
M446.005188,501.976837 
	C447.003082,503.058167 448.000946,504.139465 448.853577,505.911713 
	C441.035309,514.942688 440.256836,516.609680 442.529480,521.104858 
	C444.577454,517.694092 446.438141,514.595276 448.489655,511.178650 
	C450.042114,511.691071 452.021179,512.344299 454.000244,512.997559 
	C455.066864,512.998352 456.133453,512.999146 457.776672,513.343445 
	C458.900604,515.021179 459.801300,516.320190 459.938293,517.695374 
	C460.864471,526.995178 460.812256,527.000427 470.027100,526.999817 
	C471.351410,526.999756 472.675690,526.998352 474.000000,526.997559 
	C475.456360,526.912659 476.912750,526.827759 477.242767,526.808533 
	C480.016785,523.034424 482.230377,520.022827 484.444000,517.011169 
	C484.828308,517.193787 485.212646,517.376343 485.596985,517.558960 
	C485.069244,519.455139 484.541534,521.351379 484.006989,523.624756 
	C484.000183,524.001953 483.993896,524.014038 483.627991,524.052856 
	C482.514709,525.725159 481.767303,527.358704 481.010498,528.998901 
	C481.001129,529.005554 480.991882,529.023865 480.768127,529.158081 
	C480.673187,529.846313 480.802032,530.400269 480.953033,531.205688 
	C480.984558,531.625122 480.993896,531.793091 481.003265,531.961121 
	C480.816040,532.457031 480.628784,532.953003 480.006348,533.730713 
	C479.384735,534.014465 479.198334,534.014893 478.841064,533.781982 
	C478.119568,534.036743 477.568939,534.523254 477.010376,535.376221 
	C476.313873,537.727783 475.625275,539.712891 474.936707,541.697998 
	C474.818207,542.259949 474.699707,542.821838 474.070374,543.811646 
	C473.042603,545.150818 472.525665,546.062073 472.008728,546.973389 
	C466.674561,556.688110 461.421387,566.448486 455.972961,576.098694 
	C453.124664,581.143616 449.933228,585.994751 446.597168,591.056458 
	C446.174500,589.350708 446.051422,587.521912 445.883514,585.026184 
	C444.708832,585.698486 443.332397,586.486328 441.991699,587.253601 
	C439.565094,581.439453 440.533722,579.681946 446.763031,578.980286 
	C452.925934,578.286072 454.369659,576.311707 452.255096,571.463135 
	C451.936279,571.486755 451.467896,571.389648 451.321442,571.563538 
	C446.580902,577.194092 446.601410,577.181702 441.188660,571.897766 
	C440.370697,571.099243 439.210876,570.648010 438.404633,569.840881 
	C435.416931,566.850281 434.122650,568.155090 432.962524,571.540100 
	C432.397614,573.188293 430.794708,574.480591 429.661621,575.934021 
	C428.441223,574.071350 426.324463,572.271301 426.188995,570.332825 
	C425.915833,566.424011 425.686096,562.948547 421.251587,561.701233 
	C420.072876,561.369751 418.743896,561.578613 417.484497,561.525696 
	C416.318726,561.476746 415.153717,561.409668 413.988342,561.350586 
	C413.882172,560.926270 413.776001,560.501953 413.669830,560.077637 
	C412.230560,561.678467 410.791290,563.279297 408.970703,564.875732 
	C413.060638,556.567322 417.388092,548.181946 422.037781,539.979126 
	C428.569763,528.455688 435.341125,517.067932 442.502747,505.259766 
	C443.998627,503.923798 445.001892,502.950317 446.005188,501.976837 
M415.367065,556.169250 
	C420.308868,556.818604 421.471008,552.551575 423.428711,549.626282 
	C424.112244,548.604858 423.413422,546.658325 423.289764,543.867493 
	C420.032379,548.651062 417.697632,552.079712 415.367065,556.169250 
z"/>
<path fill="#42D2C7" opacity="1.000000" stroke="none" 
	d="
M394.491364,455.037170 
	C396.263550,454.775208 397.728119,454.624451 399.791107,454.412109 
	C400.178101,455.992676 401.143616,457.755005 400.896210,459.326355 
	C400.091949,464.434082 403.086273,465.589081 407.089142,465.963318 
	C410.024628,466.237762 413.888306,465.428223 415.638489,466.997528 
	C417.319031,468.504395 416.711517,472.471313 417.286469,475.321533 
	C418.580444,481.736481 418.583435,481.510345 424.922668,480.295288 
	C427.226440,479.853699 429.781250,480.721954 432.633209,481.009216 
	C430.050720,486.725403 427.239197,492.530243 424.027557,498.104584 
	C415.006409,513.762329 405.820404,529.325073 396.362976,544.607727 
	C397.226135,541.615417 398.401001,538.929321 399.631042,536.268616 
	C401.524200,532.173584 403.455231,528.096008 404.692291,525.457153 
	C400.556335,523.226868 397.365509,521.757385 394.454010,519.859436 
	C391.986084,518.250549 390.009003,515.873413 387.508270,514.334167 
	C384.715576,512.615234 383.723969,510.926422 384.538727,507.444153 
	C385.576294,503.009674 386.073547,498.175568 381.661102,495.475464 
	C378.752747,496.431824 376.434540,497.194122 374.116333,497.956451 
	C373.824402,497.607758 373.532501,497.259094 373.240570,496.910431 
	C365.497101,509.602936 357.753632,522.295471 350.010132,534.987976 
	C349.664642,535.000854 349.319122,535.013672 348.720215,535.065735 
	C352.040955,528.453735 355.485107,521.728516 359.216675,515.166748 
	C366.652771,502.090759 374.230499,489.095062 381.795380,476.092743 
	C382.783905,474.393738 384.041016,472.850952 385.842712,471.070557 
	C388.933624,468.333801 391.454895,465.841888 393.655548,463.093262 
	C393.940338,462.737610 392.567719,461.054993 391.962830,459.987030 
	C392.703156,458.374146 393.443451,456.761261 394.491364,455.037170 
z"/>
<path fill="#3DD7C2" opacity="1.000000" stroke="none" 
	d="
M350.377197,535.067261 
	C357.753632,522.295471 365.497101,509.602936 373.240570,496.910431 
	C373.532501,497.259094 373.824402,497.607758 374.116333,497.956451 
	C376.434540,497.194122 378.752747,496.431824 381.661102,495.475464 
	C386.073547,498.175568 385.576294,503.009674 384.538727,507.444153 
	C383.723969,510.926422 384.715576,512.615234 387.508270,514.334167 
	C390.009003,515.873413 391.986084,518.250549 394.454010,519.859436 
	C397.365509,521.757385 400.556335,523.226868 404.692291,525.457153 
	C403.455231,528.096008 401.524200,532.173584 399.631042,536.268616 
	C398.401001,538.929321 397.226135,541.615417 396.036560,544.703979 
	C396.009369,545.281860 395.972198,545.445923 395.462036,545.863525 
	C393.345856,549.129944 391.702667,552.142822 390.059479,555.155701 
	C390.059479,555.155701 389.949615,555.640137 389.452789,555.884766 
	C388.339905,557.511536 387.723846,558.893616 387.107788,560.275696 
	C387.107788,560.275696 387.086365,560.704468 386.927673,560.850342 
	C385.149841,562.721863 383.530731,564.447632 381.768158,566.326233 
	C378.335205,564.887329 375.977570,562.625549 377.354126,557.827576 
	C377.619843,556.901489 375.715515,555.127686 374.521454,554.074768 
	C373.449402,553.129456 370.867035,552.658325 370.853790,551.942749 
	C370.722534,544.859680 365.006958,545.726929 360.985138,545.333252 
	C354.975555,544.745056 351.836060,542.234314 351.769501,536.115112 
	C351.765961,535.788574 351.101196,535.469238 350.377197,535.067261 
M395.574829,532.373413 
	C395.169800,533.322205 394.764801,534.271057 394.359802,535.219849 
	C395.356964,535.443909 396.354156,535.667908 398.324127,536.110474 
	C397.296082,534.260376 396.777374,533.326904 395.574829,532.373413 
M378.685577,505.527649 
	C379.024780,504.979736 379.363983,504.431824 379.703156,503.883881 
	C379.428497,503.748840 379.153839,503.613800 378.879150,503.478729 
	C378.673828,504.015259 378.468475,504.551788 378.685577,505.527649 
z"/>
<path fill="#5CC4E0" opacity="1.000000" stroke="none" 
	d="
M478.990784,450.973267 
	C476.011658,450.683411 474.401398,451.940002 472.839050,454.681519 
	C465.514465,467.534180 457.815155,480.173279 449.966949,492.974274 
	C450.573273,490.793854 451.206757,488.383118 452.393982,486.285889 
	C458.772583,475.018005 465.288300,463.827728 471.758209,452.611542 
	C473.763245,449.135559 476.905121,448.446838 480.686157,448.453979 
	C511.982086,448.513062 543.278198,448.448303 574.574280,448.400543 
	C576.310852,448.397888 578.047119,448.213593 580.549133,448.069885 
	C579.582336,445.837830 579.094971,444.290405 578.294617,442.926636 
	C559.800049,411.412659 541.224060,379.946320 522.770325,348.408478 
	C514.388062,334.083008 506.259857,319.608978 497.961670,305.233917 
	C495.724731,301.358795 493.083099,297.704651 491.037872,293.737396 
	C490.154663,292.024139 489.369293,289.246521 490.148163,287.848785 
	C497.094543,275.382477 504.398529,263.115326 511.615631,250.800339 
	C511.766937,250.542114 512.139038,250.413269 512.738586,250.184845 
	C513.038757,250.860641 513.008545,251.575638 512.975342,252.649231 
	C506.621185,263.861633 500.350922,274.764099 493.864746,285.536560 
	C492.148376,288.387177 491.553009,290.612854 493.634918,293.678589 
	C498.570221,300.945953 503.295471,308.373505 507.742188,315.949646 
	C511.392120,322.168304 514.495605,328.706543 517.896240,335.073761 
	C518.579590,336.353241 519.510010,337.500793 520.653564,339.243469 
	C521.710815,341.425140 522.280579,343.168427 523.198975,344.703094 
	C527.070251,351.171906 531.132629,357.527069 534.963013,364.019440 
	C541.286804,374.737976 547.430969,385.562561 553.757690,396.279327 
	C557.351685,402.367096 561.342163,408.225250 564.823303,414.374084 
	C569.364990,422.396149 573.573730,430.606567 577.936462,438.730164 
	C579.692383,441.999817 581.476807,445.254120 583.774841,449.484924 
	C580.193604,449.797577 578.105713,449.979889 575.602783,450.166321 
	C569.614929,450.127502 564.042114,450.050018 558.469238,450.047394 
	C533.547424,450.035583 508.625641,450.028351 483.703979,450.086578 
	C482.132233,450.090240 480.561829,450.664459 478.990784,450.973267 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M497.368713,503.934814 
	C497.160919,497.991028 502.466064,494.338898 508.395447,494.481018 
	C521.304565,494.790405 534.234558,494.226807 548.047607,494.010132 
	C567.528381,494.000183 586.117493,494.000122 604.706665,494.000000 
	C611.426270,493.999939 618.148621,494.116119 624.864441,493.955994 
	C628.914612,493.859436 632.954651,493.333191 637.428101,493.001251 
	C645.042053,493.414581 652.227051,493.826660 660.043213,494.274963 
	C659.412292,492.129242 659.300720,490.937805 658.750427,490.005310 
	C653.720032,481.481201 648.634644,472.989288 643.519592,464.515533 
	C641.086060,460.484100 638.351440,456.621155 636.124207,452.482208 
	C633.962280,448.464508 632.675842,443.945374 630.301575,440.078217 
	C624.201965,430.143372 617.593201,420.521667 611.459717,410.606689 
	C609.860413,408.021454 609.557434,404.662659 608.128052,401.937714 
	C605.727173,397.360626 603.041443,392.914551 600.207458,388.588959 
	C598.021240,385.252014 595.424194,382.184265 593.009766,378.996826 
	C593.004639,377.638245 592.999573,376.279633 592.985840,374.516235 
	C596.634705,379.772003 600.510681,385.304535 603.913757,391.114136 
	C623.990295,425.389099 643.958130,459.727722 663.944824,494.055267 
	C664.177490,494.454895 664.174500,494.991699 664.429321,496.117401 
	C662.392456,496.309692 660.512756,496.640839 658.632874,496.642120 
	C610.010925,496.675232 561.388428,496.794739 512.767822,496.537750 
	C506.110382,496.502563 500.724762,497.235443 497.368713,503.934814 
M655.412109,482.483154 
	C655.412109,482.483154 655.522339,482.417542 655.412109,482.483154 
z"/>
<path fill="#42C8CB" opacity="1.000000" stroke="none" 
	d="
M656.571228,623.307312 
	C656.692993,623.663147 656.814758,624.018982 657.023499,625.011719 
	C657.758301,627.273376 658.406189,628.898071 659.482605,631.597656 
	C652.205505,631.597656 645.619568,631.597656 639.110168,631.287964 
	C643.316895,627.361206 644.222717,623.519165 641.842590,620.384399 
	C638.453613,615.920959 638.974548,609.438110 634.051697,605.628174 
	C632.498474,604.426147 632.073608,601.856628 630.672913,600.336182 
	C629.709534,599.290588 627.954224,598.974609 626.552917,598.332520 
	C626.033691,599.637329 625.580933,600.973694 624.980591,602.240112 
	C623.920044,604.477173 623.294922,607.936768 621.562378,608.656189 
	C615.379456,611.223328 608.440918,606.265442 608.008179,599.680786 
	C607.811829,596.694519 608.694153,595.036621 612.103455,594.928772 
	C615.595154,594.818298 619.067688,594.100464 622.548584,593.648438 
	C622.518433,593.060974 622.488342,592.473572 622.458252,591.886108 
	C620.918762,591.886108 619.378052,591.926208 617.840027,591.878967 
	C612.082703,591.702087 606.327087,591.466919 600.569275,591.309570 
	C594.171692,591.134766 587.770874,591.060669 581.377136,590.801208 
	C580.894592,590.781616 580.458008,589.630188 580.468628,589.002869 
	C599.008057,588.984070 617.078735,588.967285 635.412476,589.164917 
	C637.497375,592.514954 639.242188,595.698486 641.156250,598.776855 
	C646.255676,606.977844 651.427917,615.133606 656.571228,623.307312 
M638.404663,599.090942 
	C637.752441,598.991699 637.100159,598.892395 636.447937,598.793152 
	C636.671692,599.331482 636.895447,599.869812 637.119202,600.408142 
	C637.638062,600.145020 638.156982,599.881958 638.404663,599.090942 
M643.685364,612.527588 
	C644.024719,611.979675 644.364014,611.431763 644.703369,610.883850 
	C644.428711,610.748779 644.153992,610.613647 643.879333,610.478577 
	C643.673889,611.015137 643.468506,611.551697 643.685364,612.527588 
z"/>
<path fill="#5CC4E0" opacity="1.000000" stroke="none" 
	d="
M746.250122,507.147034 
	C744.709534,504.769257 743.485291,502.518799 742.197205,500.305481 
	C738.816956,494.497101 735.415894,488.700714 732.010254,482.907166 
	C726.071655,472.804749 720.047485,462.751709 714.203491,452.594910 
	C709.631958,444.649628 705.283508,436.576141 700.827515,428.564270 
	C695.359985,418.733673 689.976624,408.854584 684.377625,399.099304 
	C680.799316,392.864594 676.896606,386.815979 673.066406,380.344879 
	C666.331909,368.340515 659.702087,356.656860 652.995728,345.017303 
	C649.420532,338.812286 645.674011,332.705963 642.101990,326.499176 
	C641.475830,325.410950 641.331787,324.045319 640.979126,322.421814 
	C643.396667,325.537140 646.024597,328.907166 648.163330,332.563354 
	C655.786499,345.595398 663.231995,358.731293 670.765747,371.815796 
	C681.621643,390.670319 692.438232,409.547852 703.376770,428.354431 
	C717.567322,452.752258 731.955078,477.035736 746.082642,501.469788 
	C758.961426,523.743958 771.602661,546.155457 784.355835,568.502258 
	C790.441101,579.164978 796.543274,589.818115 802.744507,601.134644 
	C802.836975,602.216248 802.822693,602.638672 802.808472,603.061096 
	C799.516418,598.058838 796.086914,593.140747 792.961487,588.036560 
	C786.783386,577.947021 780.683289,567.807068 774.732239,557.582397 
	C771.757874,552.472107 769.307495,547.058838 766.398254,541.908020 
	C761.801331,533.769409 757.017700,525.736389 752.122559,516.986084 
	C750.638245,514.201599 749.344421,512.089661 748.050659,509.977722 
	C747.546265,509.082245 747.041870,508.186737 746.250122,507.147034 
z"/>
<path fill="#5CC4E0" opacity="1.000000" stroke="none" 
	d="
M584.997803,805.001343 
	C585.027283,804.553223 585.056763,804.105164 585.372620,803.053467 
	C585.775391,800.968567 585.891724,799.487122 586.008057,798.005737 
	C586.037598,797.556702 586.067200,797.107666 586.381226,796.039734 
	C586.783630,793.618469 586.901611,791.816040 587.019653,790.013672 
	C587.051575,789.562134 587.083557,789.110596 587.400208,788.022583 
	C588.125916,783.259155 588.566956,779.132202 589.008057,775.005188 
	C589.039062,774.556458 589.070068,774.107788 589.382935,773.066711 
	C589.751160,771.293457 589.837585,770.112488 589.924072,768.931580 
	C589.995789,768.193054 590.067444,767.454590 590.418579,766.068726 
	C590.782776,763.935852 590.867554,762.450378 590.952271,760.964905 
	C590.984314,760.526672 591.016357,760.088379 591.339111,759.030762 
	C591.794128,755.992615 591.958374,753.573914 592.122681,751.155212 
	C592.765442,746.385376 593.180176,741.566467 594.190369,736.875793 
	C594.492065,735.474731 596.423828,733.432922 597.670959,733.387390 
	C606.128235,733.078735 614.602112,733.226074 623.646057,733.226074 
	C625.088440,741.884644 626.531799,750.333191 627.899231,758.794067 
	C634.068970,796.970459 640.183167,835.156006 646.430542,873.319702 
	C646.998291,876.787659 645.874573,877.871704 642.602051,877.782410 
	C638.276978,877.664429 633.921387,878.020264 629.632080,877.612732 
	C628.248535,877.481262 626.158081,875.763550 625.889893,874.462524 
	C624.652588,868.460938 623.728760,862.371216 623.135986,856.270020 
	C622.834656,853.167847 621.939209,851.795959 618.643982,851.938904 
	C613.492065,852.162354 608.321106,852.146362 603.165100,851.995544 
	C600.162354,851.907837 599.132324,853.089294 598.911743,856.036438 
	C598.453613,862.158203 597.478821,868.240479 596.982788,874.360596 
	C596.749207,877.242737 595.342590,878.107788 591.912292,877.897949 
	C586.136353,877.855957 581.231079,877.855957 575.428894,877.855957 
	C576.388123,872.232666 577.180420,867.588013 577.984009,863.414795 
	C578.015320,877.118530 578.015320,877.118408 590.330261,875.995239 
	C590.495605,875.980164 590.673035,876.024597 590.828186,875.981384 
	C593.230835,875.312988 595.475647,874.848450 595.157715,871.273071 
	C594.890320,868.265991 595.597595,865.174500 595.850281,862.118469 
	C596.198242,857.911926 596.524536,853.703613 596.852661,849.589111 
	C604.696655,849.589111 613.431152,849.589111 623.039307,849.589111 
	C623.604187,851.281860 624.627563,853.327820 624.930542,855.475403 
	C625.506958,859.560852 625.115845,863.856567 626.188538,867.774109 
	C626.999939,870.737610 628.892822,874.198975 631.359863,875.676880 
	C633.534607,876.979675 637.191895,876.040833 640.156494,875.729675 
	C643.421631,875.386841 644.435303,873.398499 643.895447,870.115356 
	C641.718445,856.875244 639.501770,843.635742 637.690552,830.342773 
	C634.920654,810.014221 629.473999,790.160400 627.719055,769.619446 
	C626.762634,758.425415 623.676086,747.413391 621.455322,735.823730 
	C612.773804,735.823730 605.241455,735.823730 597.551758,735.823730 
	C596.417969,740.230042 594.830872,744.869507 594.087830,749.640503 
	C592.945435,756.976074 592.309875,764.395142 591.614014,771.793335 
	C591.373413,774.351990 591.922241,777.003540 591.541077,779.526733 
	C590.524353,786.257446 589.313904,792.965149 587.944946,799.633667 
	C587.292358,802.812256 586.104614,805.881104 585.132690,808.600220 
	C585.070007,807.134277 585.033936,806.067810 584.997803,805.001343 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M639.033630,631.597656 
	C645.619568,631.597656 652.205505,631.597656 659.482605,631.597656 
	C658.406189,628.898071 657.758301,627.273376 657.123718,625.275513 
	C658.645935,627.017700 660.154968,629.133057 661.663940,631.248474 
	C659.478210,632.121155 657.294006,633.751953 655.106506,633.756409 
	C587.199951,633.893799 519.293030,633.877136 451.386200,633.865479 
	C446.572235,633.864624 445.211212,632.363953 447.187134,628.841919 
	C454.967529,614.973694 462.944824,601.215820 470.878204,587.433777 
	C471.187775,586.896057 471.795959,586.530151 472.598450,586.020325 
	C472.260315,587.726746 471.588745,589.497009 470.679016,591.874878 
	C469.923279,593.580872 469.405670,594.679138 468.645905,595.978394 
	C468.159241,596.751648 467.914764,597.323975 467.408844,598.086304 
	C466.073578,600.150757 464.999725,602.025269 463.690735,604.091492 
	C463.273346,604.851685 463.091095,605.420105 462.850281,606.145874 
	C462.791718,606.303284 462.787689,606.639221 462.500793,606.775818 
	C461.519897,607.940979 460.825836,608.969482 460.022064,610.336182 
	C456.869446,616.338379 453.826569,622.002258 450.518127,627.766296 
	C449.879333,628.260986 449.506134,628.655579 449.060425,629.364197 
	C449.335236,630.154541 449.682617,630.630920 450.388550,631.146851 
	C451.466522,631.193054 452.185913,631.199829 453.261719,631.421082 
	C457.088593,631.710632 460.559326,631.861450 464.029419,631.848511 
	C485.351471,631.769104 506.673309,631.652710 527.995239,631.548584 
	C541.357910,631.645691 554.720520,631.742798 568.970703,631.893555 
	C571.565369,631.883423 573.272461,631.819763 574.979553,631.756042 
	C582.454834,631.713867 589.930054,631.671753 598.185913,631.406189 
	C612.322144,631.321045 625.677917,631.459351 639.033630,631.597656 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M798.539551,631.700623 
	C805.245544,631.600464 811.491333,631.600464 818.670227,631.600464 
	C816.317749,626.774231 814.617981,623.287048 812.924744,619.430420 
	C815.544189,622.735718 818.273010,626.338928 820.632263,630.169678 
	C820.949280,630.684448 819.655518,632.820740 818.653503,633.356201 
	C817.449585,633.999512 815.759338,633.842529 814.279541,633.843323 
	C774.206055,633.865051 734.132385,633.899109 694.059326,633.766235 
	C691.839355,633.758911 688.488892,633.005310 687.580261,631.467346 
	C677.224548,613.938782 667.241516,596.190002 657.234375,578.129822 
	C661.117554,583.721191 665.103638,589.577637 668.693298,595.667725 
	C674.844238,606.103088 680.816895,616.645630 686.713928,627.227112 
	C688.616577,630.641052 690.967163,632.152344 695.069702,631.978333 
	C706.844971,631.478577 718.639465,631.195862 730.425232,631.230530 
	C751.979919,631.293945 773.533752,631.648132 795.088013,631.874084 
	C796.084778,631.884521 797.082214,631.826782 798.539551,631.700623 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M511.613586,520.877991 
	C509.717163,523.592102 507.820740,526.306213 505.607300,529.126465 
	C506.366638,519.786499 511.456207,515.091980 520.548584,515.092407 
	C570.448242,515.094971 620.347900,515.074829 670.247498,515.126404 
	C672.114258,515.128357 673.980408,515.789612 675.478516,516.571411 
	C666.410889,517.057556 657.711426,517.115906 649.012024,517.174255 
	C646.633362,517.138000 644.254639,517.101746 641.075317,517.059082 
	C639.125183,517.263367 637.975769,517.474060 636.826355,517.684753 
	C619.177795,517.462646 601.529236,517.235962 583.880554,517.023804 
	C581.564026,516.995911 579.246643,517.042480 576.463623,517.115356 
	C555.640625,517.147644 535.283630,517.119202 514.145752,517.241089 
	C512.781067,518.553528 512.197327,519.715759 511.613586,520.877991 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M537.970825,566.284302 
	C540.703979,565.015320 543.325867,563.163147 545.963989,563.139526 
	C571.663452,562.909851 597.365601,562.985901 623.533508,562.922729 
	C630.483521,562.911865 636.970459,562.847412 643.448608,563.055603 
	C645.523193,563.122375 647.574524,563.910278 649.748413,564.747559 
	C630.774231,565.043640 611.688293,564.936829 592.602173,564.884888 
	C577.828430,564.844666 563.050415,565.072510 548.283142,564.760620 
	C543.254944,564.654419 539.702820,565.667603 537.711487,570.648682 
	C536.811829,572.899170 535.153442,574.846375 533.410034,576.959961 
	C532.980896,576.988831 532.752075,576.796814 532.820618,576.475586 
	C533.277649,575.133423 533.666138,574.112488 534.054565,573.091614 
	C534.384155,572.613159 534.713745,572.134644 535.307739,571.173584 
	C535.746582,570.151733 535.920959,569.612366 536.095337,569.072998 
	C536.095337,569.072998 536.070190,569.006775 536.291870,568.943115 
	C536.668274,568.588440 536.823059,568.297485 536.977783,568.006470 
	C537.274170,567.520813 537.570557,567.035156 537.970825,566.284302 
z"/>
<path fill="#5CC4E0" opacity="1.000000" stroke="none" 
	d="
M640.680725,320.693512 
	C639.053406,319.458496 637.025818,318.119751 635.954895,316.236877 
	C621.623413,291.038147 607.424072,265.764191 593.195679,240.506836 
	C590.445435,235.624832 587.710876,230.734085 584.967651,225.482880 
	C587.469116,228.706253 590.240662,232.137909 592.431946,235.906921 
	C608.406799,263.383667 624.267334,290.926971 640.143616,318.460907 
	C640.462585,319.014069 640.536743,319.708405 640.680725,320.693512 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M529.047058,585.825317 
	C529.278198,584.127319 529.509338,582.429260 530.125793,580.213745 
	C530.686218,579.141785 530.861328,578.587341 531.036438,578.032959 
	C531.036438,578.032959 531.013123,577.998413 531.252197,577.887939 
	C531.911560,577.450562 532.331848,577.123657 532.752075,576.796814 
	C532.752075,576.796814 532.980896,576.988831 533.096558,577.085938 
	C532.560608,579.188293 531.908997,581.193665 531.046326,583.848511 
	C533.134033,583.943970 534.862671,584.091187 536.591309,584.091919 
	C567.310303,584.104736 598.029358,584.063416 628.747925,584.179260 
	C630.833374,584.187073 632.914551,585.347900 635.030762,586.449036 
	C635.063782,586.925598 634.842896,587.156799 634.420227,587.073730 
	C627.218201,586.688965 620.440674,586.185242 613.659180,586.126465 
	C598.560181,585.995667 583.459045,586.121643 568.359009,586.078064 
	C555.254883,586.040222 542.151062,585.913025 529.047058,585.825317 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M649.110229,517.542603 
	C657.711426,517.115906 666.410889,517.057556 675.582581,516.959595 
	C676.334167,517.091125 676.583252,517.298645 676.924194,518.047668 
	C677.549866,519.138489 678.053528,519.724182 678.557190,520.309875 
	C679.245361,521.695312 679.933472,523.080688 680.818848,525.030762 
	C681.571533,526.147095 682.127136,526.698792 682.682678,527.250549 
	C682.682678,527.250549 682.800171,527.655701 682.903931,528.101807 
	C683.155884,528.867493 683.395996,529.085876 683.728088,529.203125 
	C683.728088,529.203125 683.874695,529.595581 683.967041,530.045166 
	C684.299500,530.720398 684.539673,530.945923 684.779785,531.171509 
	C685.427795,532.256958 686.075806,533.342346 686.855347,535.022705 
	C687.526550,536.177307 688.066345,536.737061 688.606079,537.296753 
	C688.907654,538.067139 689.209290,538.837524 689.741455,540.168823 
	C690.976990,542.156677 691.982117,543.583496 692.987244,545.010315 
	C692.987244,545.010315 693.000000,545.000000 693.000488,545.359619 
	C694.321106,547.822388 695.641357,549.925476 696.961548,552.028564 
	C696.961548,552.028564 697.000000,552.004883 696.956299,552.347839 
	C696.943237,553.127869 696.973755,553.564880 697.004272,554.001953 
	C695.305481,552.261719 693.221375,550.759338 691.974182,548.740112 
	C686.479187,539.843628 681.216064,530.803711 675.888489,521.804199 
	C673.285400,517.406799 669.198242,519.447754 665.550049,519.257507 
	C660.094238,518.973083 654.654846,518.375427 649.110229,517.542603 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M546.071472,744.715942 
	C545.365356,741.586670 544.960083,738.191040 544.471130,734.094543 
	C538.482056,734.094543 532.387817,733.915222 526.329224,734.299194 
	C525.478210,734.353149 524.155396,737.160278 524.143799,738.701172 
	C524.009521,756.486572 524.086060,774.273499 524.057617,792.526001 
	C523.717346,792.077576 523.189575,791.168335 523.169922,790.248230 
	C522.804993,773.195435 522.449280,756.142090 522.211304,739.087219 
	C522.127930,733.113220 523.185852,732.194336 529.130554,732.178406 
	C534.420837,732.164246 539.711182,732.175415 546.372253,732.175415 
	C546.372253,736.472839 546.372253,740.461182 546.071472,744.715942 
z"/>
<path fill="#5CC4E0" opacity="1.000000" stroke="none" 
	d="
M544.718323,194.185211 
	C541.360596,201.240219 537.773132,208.415131 533.984253,215.482132 
	C528.631226,225.466492 523.186157,235.403000 517.635620,245.278564 
	C516.703979,246.936142 515.040039,248.182144 513.458618,249.825195 
	C513.306580,249.045166 513.104675,247.880768 513.559326,247.097397 
	C523.795593,229.458649 534.103882,211.861710 544.718323,194.185211 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M577.972778,862.943420 
	C577.180420,867.588013 576.388123,872.232666 575.428894,877.855957 
	C581.231079,877.855957 586.136353,877.855957 591.504028,877.905884 
	C591.391724,878.241760 590.815918,878.779175 590.242249,878.776917 
	C574.378418,878.714844 558.514832,878.598022 542.353516,878.208130 
	C550.946228,877.618652 559.836670,877.100708 568.727112,877.100525 
	C572.655762,877.100464 574.511841,876.279419 574.085815,871.932068 
	C573.700684,868.002686 573.956421,864.010498 573.912476,859.590210 
	C575.118591,858.280090 576.341736,857.425842 577.857910,856.768372 
	C578.114197,858.643860 578.077454,860.322510 578.009521,862.235718 
	C577.978333,862.470276 577.972778,862.943420 577.972778,862.943420 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M547.960083,858.521118 
	C547.351440,857.568176 546.118286,856.154175 546.108337,854.731689 
	C545.948425,831.796631 545.944031,808.860474 545.961365,785.458008 
	C546.304565,786.746399 546.812561,788.497375 546.843201,790.256714 
	C547.236755,812.855774 547.562134,835.456116 547.960083,858.521118 
z"/>
<path fill="#45B1D7" opacity="1.000000" stroke="none" 
	d="
M578.982605,306.009949 
	C580.355225,307.081787 582.192078,307.877045 583.023071,309.271667 
	C587.481079,316.753387 591.637146,324.414368 596.020081,331.942078 
	C597.075623,333.755005 598.925049,335.140442 599.814148,337.006134 
	C600.762024,338.995300 601.004456,341.320587 601.038696,343.604340 
	C599.855530,343.145721 599.188538,342.581909 598.521606,342.018097 
	C598.391602,341.862000 598.261597,341.705872 598.035767,340.934875 
	C597.176025,339.223572 596.412170,338.127136 595.648315,337.030731 
	C595.495544,336.876526 595.342712,336.722290 595.054932,335.904297 
	C592.422913,330.207184 589.966919,325.152557 587.374023,320.169159 
	C587.069580,319.583984 586.012146,319.390594 585.303772,319.015564 
	C585.303772,319.015564 584.998718,318.634033 584.963623,318.093262 
	C584.444519,317.041473 583.960510,316.530396 583.476440,316.019348 
	C583.352295,315.875519 583.228149,315.731659 583.014160,314.960632 
	C581.779297,312.563934 580.634338,310.794434 579.489380,309.024902 
	C579.364502,308.871857 579.239685,308.718842 579.054199,307.947083 
	C578.989807,306.888855 578.986206,306.449402 578.982605,306.009949 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M736.952393,580.026367 
	C737.123718,580.575378 737.295105,581.124451 737.685669,582.213013 
	C737.854431,583.469177 737.804077,584.185669 737.753662,584.902222 
	C737.753723,584.902222 737.365173,584.937439 736.755615,585.008301 
	C729.432617,585.083435 722.719238,585.087769 716.005859,585.092163 
	C716.005859,585.092163 715.590149,584.976929 715.287109,584.613098 
	C711.687256,579.136353 708.390381,574.023499 705.093506,568.910583 
	C705.093506,568.910583 705.018372,568.921997 705.058594,568.564941 
	C705.073120,567.466003 705.047424,566.724182 705.021790,565.982361 
	C707.894470,569.856750 711.369507,573.432983 713.475342,577.687012 
	C715.716553,582.214355 718.720581,583.515625 723.358826,583.144531 
	C727.070007,582.847534 730.823975,583.084534 735.125305,583.084534 
	C733.963501,579.973083 733.116760,577.705505 732.630859,575.172607 
	C733.657166,575.610352 734.322754,576.313416 734.988342,577.016418 
	C734.988342,577.016418 734.999023,576.999695 735.032959,577.318970 
	C735.695312,578.434326 736.323853,579.230347 736.952393,580.026367 
z"/>
<path fill="#5CC4E0" opacity="1.000000" stroke="none" 
	d="
M584.503052,223.714142 
	C581.284485,219.222015 577.958069,214.514145 575.095825,209.539139 
	C569.892517,200.494888 564.965027,191.291885 559.868286,181.825531 
	C563.082642,186.551895 566.542664,191.496002 569.577087,196.688782 
	C574.727478,205.502625 579.609558,214.473221 584.503052,223.714142 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M523.898804,823.301514 
	C524.458496,824.277466 524.935120,825.540039 524.940735,826.804749 
	C525.000793,840.270264 524.986084,853.736084 524.897461,867.676514 
	C524.273193,867.392944 523.280457,866.633667 523.282288,865.876709 
	C523.316650,851.780701 523.469299,837.684937 523.898804,823.301514 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M656.669434,622.939575 
	C651.427917,615.133606 646.255676,606.977844 641.156250,598.776855 
	C639.242188,595.698486 637.497375,592.514954 635.370850,588.864258 
	C634.991821,587.951721 634.917358,587.554260 634.842896,587.156799 
	C634.842896,587.156799 635.063782,586.925598 635.172913,586.805542 
	C637.308228,589.425659 639.553223,592.032593 641.317932,594.931885 
	C645.870300,602.411255 650.242859,610.001221 654.608337,617.592407 
	C655.504517,619.150818 656.056824,620.906921 656.669434,622.939575 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M598.478149,342.355377 
	C599.188538,342.581909 599.855530,343.145721 600.758789,343.861084 
	C601.546387,344.836823 602.097717,345.661011 602.825073,346.745972 
	C607.369263,354.396851 611.856201,361.720551 616.046875,369.209961 
	C617.703308,372.170441 618.700378,375.499908 620.079834,378.975098 
	C618.909668,377.641479 617.461487,376.106903 616.437073,374.328247 
	C610.381714,363.814514 604.424744,353.244019 598.478149,342.355377 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M555.416077,271.202576 
	C556.371399,271.273010 558.365784,271.162079 558.816956,271.942902 
	C564.979492,282.607452 570.928589,293.395325 576.992188,304.496643 
	C573.829834,299.755493 570.520203,294.722260 567.406006,289.570831 
	C564.416199,284.625153 561.687195,279.522064 558.710815,274.567932 
	C557.987122,273.363281 556.742676,272.471497 555.416077,271.202576 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M571.452942,336.636414 
	C567.473328,330.767578 563.484253,324.622192 559.730164,318.336456 
	C557.308472,314.281586 555.231323,310.020966 552.978882,305.492615 
	C554.842834,307.945770 556.877747,310.671051 558.583069,313.589020 
	C562.980896,321.114014 567.232910,328.724182 571.452942,336.636414 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M734.992310,576.610718 
	C734.322754,576.313416 733.657166,575.610352 732.721680,574.805298 
	C732.068420,573.979126 731.685120,573.254944 731.164917,571.956970 
	C730.536377,570.806458 730.044739,570.229797 729.553040,569.653137 
	C729.553040,569.653137 729.369812,569.240723 729.255127,568.684631 
	C727.563110,565.652771 725.985840,563.177002 724.408569,560.701233 
	C724.025574,559.986023 723.642639,559.270874 723.130005,557.975830 
	C722.461487,556.834351 721.922546,556.272766 721.383606,555.711243 
	C721.383606,555.711243 721.234375,555.320740 721.185669,554.802063 
	C721.140259,553.847351 721.143433,553.411255 721.146606,552.975220 
	C722.088867,553.197205 723.602539,553.127686 723.887024,553.685303 
	C727.690430,561.140686 731.321960,568.683777 734.992310,576.610718 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M552.864136,304.670166 
	C548.630127,299.284210 542.794678,294.187164 545.004700,285.403900 
	C545.937866,283.865173 546.859680,282.718964 548.116577,281.177917 
	C548.704407,280.597046 548.842834,280.347534 548.867004,280.034515 
	C550.206665,277.757446 551.546326,275.480377 553.168396,273.114258 
	C551.324646,277.255981 549.432556,281.628174 546.991699,285.668884 
	C545.305237,288.460754 545.165344,290.598694 546.987915,293.389496 
	C549.238342,296.835449 550.921143,300.652161 552.864136,304.670166 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M588.740540,366.707947 
	C585.988281,362.713501 583.094543,358.411346 580.422485,353.975677 
	C577.539978,349.190826 574.864868,344.281067 572.115479,339.040039 
	C576.030701,344.675079 580.033813,350.632080 583.785156,356.743561 
	C585.659607,359.797241 587.074097,363.133179 588.740540,366.707947 
z"/>
<path fill="#5CC4E0" opacity="1.000000" stroke="none" 
	d="
M578.040710,862.001160 
	C578.077454,860.322510 578.114197,858.643860 578.128845,856.550476 
	C578.225281,855.057007 578.343933,853.978210 578.665344,852.334961 
	C578.992126,851.512390 579.022156,851.243774 578.958130,850.964600 
	C578.976685,850.525940 578.995239,850.087341 579.319336,849.034729 
	C579.967896,845.203064 580.310913,841.985291 580.653931,838.767578 
	C580.703186,838.393433 580.752502,838.019287 580.923340,837.026611 
	C581.038147,835.944885 581.031311,835.481567 581.024475,835.018311 
	C581.041992,834.574707 581.059509,834.131042 581.331543,833.353699 
	C581.585999,833.019958 581.996460,832.989990 581.999023,833.454895 
	C582.000977,836.407593 581.942200,838.897095 582.014709,841.382751 
	C582.106934,844.544312 582.305542,847.702759 582.464722,850.994873 
	C581.730225,853.265198 580.941772,855.971741 579.962830,858.607422 
	C579.515930,859.810669 578.693054,860.874268 578.040710,862.001160 
z"/>
<path fill="#5CC4E0" opacity="1.000000" stroke="none" 
	d="
M559.631165,180.775116 
	C558.397644,179.884949 557.220398,178.648376 555.940735,177.304199 
	C552.625854,182.556198 549.369385,187.715652 545.821289,192.968231 
	C548.737671,186.885468 551.945801,180.709579 555.835144,173.222351 
	C557.521729,176.377472 558.604614,178.403091 559.631165,180.775116 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M628.546082,394.235138 
	C633.063782,401.262970 637.480713,408.627167 641.759888,416.381622 
	C641.622131,416.771851 641.202820,416.820587 641.202820,416.820587 
	C636.950317,409.404236 632.697815,401.987854 628.546082,394.235138 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M484.006409,523.996216 
	C487.285339,518.787476 490.556152,513.583191 493.832092,508.382202 
	C494.691681,507.017517 495.567169,505.662811 496.753357,504.164001 
	C492.613129,511.987701 488.154877,519.950989 483.388672,527.683289 
	C483.385132,526.306213 483.689514,525.160156 483.993896,524.014038 
	C483.993896,524.014038 484.000183,524.001953 484.006409,523.996216 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M690.476685,500.537292 
	C695.295288,500.791168 695.376221,505.637878 697.555969,508.559509 
	C698.916443,510.382996 699.188721,513.018433 699.687012,515.537476 
	C698.792053,515.391541 698.159302,514.997742 697.526611,514.603882 
	C697.526611,514.603882 697.393494,514.188110 697.291870,513.659668 
	C696.592773,512.323547 695.995300,511.515839 695.397827,510.708130 
	C695.037048,509.977905 694.676331,509.247711 694.170288,507.910217 
	C693.160400,506.100586 692.295776,504.898224 691.431213,503.695831 
	C691.133240,502.763947 690.835266,501.832031 690.476685,500.537292 
z"/>
<path fill="#42C8CB" opacity="1.000000" stroke="none" 
	d="
M391.677734,460.160461 
	C392.567719,461.054993 393.940338,462.737610 393.655548,463.093262 
	C391.454895,465.841888 388.933624,468.333801 386.134949,470.910706 
	C387.636353,467.388733 389.514496,463.861328 391.677734,460.160461 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M585.282410,319.366516 
	C586.012146,319.390594 587.069580,319.583984 587.374023,320.169159 
	C589.966919,325.152557 592.422913,330.207184 594.959717,335.592834 
	C591.753357,330.535980 588.507202,325.126740 585.282410,319.366516 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M649.966553,429.908478 
	C650.637817,431.378052 651.309082,432.847626 651.969238,434.673340 
	C649.034790,430.506500 646.111450,425.983521 643.268677,421.087189 
	C643.690247,420.798340 644.031372,420.882843 644.680542,421.305481 
	C645.329224,422.093384 645.669922,422.543152 646.010620,422.992920 
	C647.016174,424.798889 648.021790,426.604858 649.183838,428.940979 
	C649.473206,429.725403 649.681946,429.871185 649.966553,429.908478 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M496.721222,544.095459 
	C494.684906,548.383118 492.296265,552.698120 489.587708,557.114807 
	C491.634857,552.851929 494.001892,548.487305 496.721222,544.095459 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M480.636719,572.038208 
	C478.609070,576.320557 476.235382,580.647583 473.540863,585.076721 
	C475.576904,580.813538 477.933746,576.448181 480.636719,572.038208 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M488.644104,558.034485 
	C486.624573,562.335999 484.263855,566.677368 481.580017,571.113647 
	C483.605591,566.830444 485.954285,562.452393 488.644104,558.034485 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M504.636597,530.038025 
	C504.252625,532.027527 503.530701,534.071289 502.713684,536.465454 
	C502.030975,537.615540 501.443390,538.415100 500.554993,539.721375 
	C499.798248,541.136414 499.342285,542.044739 498.886353,542.953125 
	C498.536041,543.025085 498.185730,543.096985 497.572784,543.213745 
	C499.639648,538.869751 501.969147,534.480957 504.636597,530.038025 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M720.891602,552.810425 
	C721.143433,553.411255 721.140259,553.847351 721.106445,554.608643 
	C718.467834,550.767639 715.859863,546.601318 713.597473,542.155151 
	C714.535828,542.302429 715.128723,542.729492 715.721558,543.156616 
	C715.926025,543.683960 716.130493,544.211304 716.646851,545.248657 
	C717.986938,547.498840 719.015076,549.238892 720.043213,550.979004 
	C720.241028,551.534546 720.438843,552.090088 720.891602,552.810425 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M650.095398,566.278259 
	C652.403320,569.360779 654.718628,572.808777 657.020020,576.625977 
	C654.704956,573.544678 652.403931,570.094177 650.095398,566.278259 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M658.381470,447.028015 
	C659.176941,447.330658 659.858765,448.000763 660.826050,449.153809 
	C661.702393,450.127167 662.293213,450.617615 662.884033,451.108032 
	C662.884033,451.108032 662.956970,451.040527 662.913208,451.424957 
	C663.522095,452.680206 664.174805,453.551025 664.827515,454.421814 
	C664.827515,454.421814 664.768433,454.718872 664.830322,455.233032 
	C664.944153,456.483032 664.996033,457.218811 665.047974,457.954590 
	C662.787964,454.434875 660.527954,450.915161 658.381470,447.028015 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M684.956360,490.087158 
	C685.132385,490.624146 685.308472,491.161133 685.772583,492.198151 
	C687.033691,494.135651 688.006836,495.573090 688.979980,497.010529 
	C688.979980,497.010529 689.000000,497.000000 689.021851,497.302246 
	C689.358276,498.074493 689.672791,498.544556 689.987305,499.014587 
	C689.998840,499.293213 690.010315,499.571838 690.029907,500.273407 
	C689.502747,500.663208 688.616638,500.816467 688.485107,500.568878 
	C686.586914,496.995300 684.790222,493.367798 683.258545,489.451294 
	C684.017395,489.461945 684.486877,489.774567 684.956360,490.087158 
z"/>
<path fill="#5CC4E0" opacity="1.000000" stroke="none" 
	d="
M583.031311,820.024841 
	C583.065613,819.260193 583.099915,818.495544 583.440186,817.089844 
	C584.172668,814.634521 584.599121,812.820251 585.304749,811.270386 
	C584.747192,816.015259 583.910522,820.495728 583.061279,824.563416 
	C583.042908,822.775330 583.037109,821.400085 583.031311,820.024841 
z"/>
<path fill="#5CC4E0" opacity="1.000000" stroke="none" 
	d="
M446.044495,501.623566 
	C445.001892,502.950317 443.998627,503.923798 442.709045,504.957825 
	C444.423920,501.457153 446.425110,497.895966 448.748993,494.289978 
	C448.075714,496.586853 447.079773,498.928558 446.044495,501.623566 
z"/>
<path fill="#42D2C7" opacity="1.000000" stroke="none" 
	d="
M390.382080,555.052246 
	C391.702667,552.142822 393.345856,549.129944 395.303009,546.059326 
	C393.979553,548.984070 392.342133,551.966431 390.382080,555.052246 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M585.025635,811.005981 
	C584.599121,812.820251 584.172668,814.634521 583.506775,816.713013 
	C583.671509,813.155640 584.075623,809.333984 584.738770,805.256836 
	C585.033936,806.067810 585.070007,807.134277 585.072083,808.931458 
	C585.033875,810.110107 585.029724,810.558044 585.025635,811.005981 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M582.750610,820.267456 
	C583.037109,821.400085 583.042908,822.775330 583.019836,824.813538 
	C582.991516,825.645142 582.992126,825.813843 582.749146,826.274109 
	C582.364136,828.377808 582.222656,830.189880 582.052490,832.248352 
	C582.023926,832.494690 581.996460,832.989990 581.996460,832.989990 
	C581.996460,832.989990 581.585999,833.019958 581.380615,833.024353 
	C581.606812,828.855835 582.038391,824.682983 582.750610,820.267456 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M672.882446,470.414856 
	C676.980896,470.803558 678.290161,473.495483 677.722168,477.584900 
	C676.997803,477.545441 676.526184,477.251434 676.054565,476.957458 
	C675.787598,476.469330 675.520691,475.981201 675.138428,474.935852 
	C674.499146,473.812378 673.975220,473.246094 673.451233,472.679810 
	C673.264465,472.036499 673.077759,471.393188 672.882446,470.414856 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M588.737915,775.261841 
	C588.566956,779.132202 588.125916,783.259155 587.437744,787.685181 
	C587.616272,783.828979 588.042053,779.673767 588.737915,775.261841 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M572.719727,858.662720 
	C566.663757,858.975525 560.286987,859.025208 553.442566,859.018066 
	C559.449524,858.773926 565.924255,858.586670 572.719727,858.662720 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M697.468018,514.968384 
	C698.159302,514.997742 698.792053,515.391541 699.673035,515.924316 
	C699.921326,516.063232 699.940186,516.032104 699.980591,516.376343 
	C700.999878,518.164246 701.978699,519.607910 702.957520,521.051575 
	C702.957520,521.051575 702.978271,520.990417 702.982788,521.369873 
	C703.166626,522.664917 703.345825,523.580505 703.399902,524.616211 
	C703.274658,524.736267 703.023987,524.976135 703.023987,524.976135 
	C701.152466,521.761719 699.280945,518.547241 697.468018,514.968384 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M475.244934,541.631592 
	C475.625275,539.712891 476.313873,537.727783 477.306061,535.353271 
	C478.077087,534.647156 478.544525,534.330505 479.011963,534.013794 
	C479.198334,534.014893 479.384735,534.014465 479.701416,533.995483 
	C478.405518,536.507446 476.979340,539.036316 475.244934,541.631592 
z"/>
<path fill="#42D2C7" opacity="1.000000" stroke="none" 
	d="
M400.566467,444.816559 
	C399.606628,447.059113 398.330292,449.434601 396.755707,451.897949 
	C397.721588,449.640381 398.985779,447.294922 400.566467,444.816559 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M724.299683,561.079651 
	C725.985840,563.177002 727.563110,565.652771 729.147034,568.486572 
	C727.499329,566.382385 725.845093,563.920227 724.299683,561.079651 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M665.122437,458.148560 
	C664.996033,457.218811 664.944153,456.483032 664.874634,455.377869 
	C665.046936,455.006958 665.236938,455.005371 665.859741,455.305603 
	C667.532715,457.737152 668.772949,459.866882 670.013184,461.996582 
	C669.918091,462.308075 669.742249,462.564087 669.011230,462.686188 
	C667.475342,461.314453 666.413879,460.021149 665.352417,458.727844 
	C665.352417,458.727844 665.196960,458.342529 665.122437,458.148560 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M676.015259,477.335510 
	C676.526184,477.251434 676.997803,477.545441 677.706055,477.993622 
	C678.881653,479.911560 679.820679,481.675262 680.936401,484.035858 
	C681.114685,485.049011 681.116272,485.465332 681.117798,485.881622 
	C679.403809,483.158936 677.689880,480.436249 676.015259,477.335510 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M580.347900,838.949219 
	C580.310913,841.985291 579.967896,845.203064 579.358643,848.707886 
	C579.408875,845.706909 579.725403,842.418823 580.347900,838.949219 
z"/>
<path fill="#41D3C6" opacity="1.000000" stroke="none" 
	d="
M439.450439,603.253174 
	C440.320618,601.142761 441.505432,598.879883 442.886719,596.875916 
	C441.977142,599.123474 440.871124,601.112122 439.450439,603.253174 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M525.266479,878.153748 
	C529.512817,877.815918 533.771118,877.827026 538.486572,877.882935 
	C534.388672,878.119507 529.833496,878.311096 525.266479,878.153748 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M591.754150,751.227661 
	C591.958374,753.573914 591.794128,755.992615 591.367981,758.702393 
	C591.199280,756.429077 591.292480,753.864563 591.754150,751.227661 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M681.322571,486.111755 
	C681.116272,485.465332 681.114685,485.049011 681.124329,484.320099 
	C684.394043,484.320648 685.393860,486.284210 684.971008,489.681885 
	C684.486877,489.774567 684.017395,489.461945 683.275391,489.075500 
	C682.511047,488.115051 682.019226,487.228455 681.322571,486.111755 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M707.928223,530.137695 
	C711.471436,529.781616 711.935608,531.838257 710.912598,534.796509 
	C710.020142,534.881958 709.668579,534.807495 709.316956,534.733032 
	C709.316956,534.733032 709.163818,534.365234 709.090576,533.842712 
	C708.654297,532.259338 708.291260,531.198547 707.928223,530.137695 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M704.885376,565.712158 
	C705.047424,566.724182 705.073120,567.466003 705.093384,568.580200 
	C703.896973,567.095459 702.705994,565.238342 702.040283,563.217529 
	C702.904846,563.142517 703.244080,563.231201 703.583374,563.319946 
	C703.971924,564.027222 704.360413,564.734558 704.885376,565.712158 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M592.299561,372.664154 
	C591.140625,371.286926 590.061829,369.589386 588.951050,367.550781 
	C590.072632,368.921082 591.226135,370.632477 592.299561,372.664154 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M696.999756,551.654419 
	C695.641357,549.925476 694.321106,547.822388 692.994263,545.365540 
	C694.337646,547.101318 695.687805,549.190857 696.999756,551.654419 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M700.964539,561.002563 
	C699.661682,559.182373 698.358826,557.362183 697.333618,555.329224 
	C698.287781,555.504395 698.964417,555.892395 699.640991,556.280396 
	C699.640991,556.280396 699.791687,556.661804 699.874878,557.194458 
	C700.293518,558.818970 700.629028,559.910767 700.964539,561.002563 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M665.278687,459.093719 
	C666.413879,460.021149 667.475342,461.314453 668.802734,462.796631 
	C669.077820,463.425690 669.086853,463.865906 669.068481,464.634979 
	C667.762390,463.129089 666.483643,461.294342 665.278687,459.093719 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M709.245056,535.101929 
	C709.668579,534.807495 710.020142,534.881958 710.638245,534.998413 
	C711.090942,535.585388 711.277161,536.130493 711.735718,537.142090 
	C712.330383,538.077087 712.652710,538.545593 712.975098,539.014099 
	C712.975098,539.014099 713.000000,539.007629 713.010803,539.335083 
	C713.036926,540.086365 713.052246,540.510254 713.067566,540.934082 
	C711.769409,539.113037 710.471313,537.291931 709.245056,535.101929 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M579.442017,309.354523 
	C580.634338,310.794434 581.779297,312.563934 582.949524,314.683075 
	C581.781433,313.249878 580.588013,311.467010 579.442017,309.354523 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M701.216675,561.161865 
	C700.629028,559.910767 700.293518,558.818970 699.958862,557.381531 
	C701.233948,558.884888 702.508301,560.733948 703.682983,562.951416 
	C703.244080,563.231201 702.904846,563.142517 702.304626,563.012817 
	C701.852112,562.421570 701.660461,561.871338 701.216675,561.161865 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M713.147461,541.133179 
	C713.052246,540.510254 713.036926,540.086365 712.999451,539.344482 
	C714.082886,540.281616 715.188538,541.536743 716.007812,542.974243 
	C715.128723,542.729492 714.535828,542.302429 713.684204,541.794067 
	C713.425415,541.712891 713.227417,541.332214 713.147461,541.133179 
z"/>
<path fill="#41C9CA" opacity="1.000000" stroke="none" 
	d="
M483.627991,524.052856 
	C483.689514,525.160156 483.385132,526.306213 483.079773,527.815186 
	C483.078796,528.178040 483.024231,528.651611 482.587280,528.828247 
	C481.773529,529.000671 481.396698,528.996460 481.019897,528.992249 
	C481.767303,527.358704 482.514709,525.725159 483.627991,524.052856 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M625.520935,389.314514 
	C626.411133,390.210693 627.220093,391.409515 628.099426,392.889526 
	C627.259766,391.986206 626.349731,390.801697 625.520935,389.314514 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M586.759277,790.235229 
	C586.901611,791.816040 586.783630,793.618469 586.416504,795.704102 
	C586.277832,794.143860 586.388367,792.300293 586.759277,790.235229 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M590.669434,761.150879 
	C590.867554,762.450378 590.782776,763.935852 590.462646,765.700317 
	C590.280334,764.431763 590.333435,762.884277 590.669434,761.150879 
z"/>
<path fill="#42D2C7" opacity="1.000000" stroke="none" 
	d="
M387.417480,560.151428 
	C387.723846,558.893616 388.339905,557.511536 389.282471,556.065674 
	C388.981750,557.343567 388.354492,558.685364 387.417480,560.151428 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M693.006409,544.643188 
	C691.982117,543.583496 690.976990,542.156677 689.959839,540.388794 
	C690.973755,541.457214 691.999695,542.866638 693.006409,544.643188 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M707.695679,529.981812 
	C708.291260,531.198547 708.654297,532.259338 709.008179,533.661377 
	C707.996826,532.571411 706.994751,531.140259 706.021790,529.343994 
	C706.521606,529.261292 706.992371,529.543640 707.695679,529.981812 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M699.727661,555.922363 
	C698.964417,555.892395 698.287781,555.504395 697.361328,554.952393 
	C697.111450,554.788330 697.093079,554.390320 697.048706,554.196167 
	C696.973755,553.564880 696.943237,553.127869 696.937073,552.363037 
	C697.912476,553.211670 698.863403,554.388062 699.727661,555.922363 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M807.042542,610.964478 
	C807.042542,610.964478 806.972290,610.497681 806.844849,609.951843 
	C806.496948,608.585388 806.276550,607.764709 806.056152,606.944092 
	C807.051636,608.354370 808.047180,609.764587 808.995239,611.605347 
	C808.947815,612.035889 808.761963,612.187988 808.555115,612.022949 
	C807.913025,611.560120 807.477783,611.262329 807.042542,610.964478 
z"/>
<path fill="#42D2C7" opacity="1.000000" stroke="none" 
	d="
M433.342255,480.964233 
	C433.666321,479.757172 434.305908,478.467926 435.232849,477.096466 
	C434.899384,478.303528 434.278564,479.592773 433.342255,480.964233 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M691.323608,504.050385 
	C692.295776,504.898224 693.160400,506.100586 694.024292,507.641724 
	C693.087769,506.788666 692.151855,505.596802 691.323608,504.050385 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M669.298706,466.058655 
	C670.101746,466.353851 670.804504,467.006775 671.760254,468.157196 
	C672.027161,469.084778 672.041077,469.514832 672.054993,469.944916 
	C671.102783,468.768738 670.150635,467.592529 669.298706,466.058655 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M589.607056,769.028625 
	C589.837585,770.112488 589.751160,771.293457 589.422791,772.731750 
	C589.217285,771.701294 589.253662,770.413513 589.607056,769.028625 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M620.518799,380.216614 
	C621.378174,381.063080 622.157593,382.228882 622.953979,383.723999 
	C622.126892,382.880798 621.282898,381.708344 620.518799,380.216614 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M595.570190,337.336517 
	C596.412170,338.127136 597.176025,339.223572 597.969727,340.637421 
	C597.163757,339.850647 596.327942,338.746490 595.570190,337.336517 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M704.962036,525.023743 
	C705.314392,526.104492 705.666748,527.185242 706.024170,528.608521 
	C705.052307,527.855774 704.075317,526.760559 703.061157,525.320740 
	C703.023987,524.976135 703.274658,524.736267 703.621338,524.743591 
	C704.299377,524.841858 704.630737,524.932800 704.962036,525.023743 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M654.890442,438.049438 
	C655.593384,439.097076 656.296265,440.144714 656.763000,441.447601 
	C656.067810,441.430450 655.608826,441.158081 655.149841,440.885681 
	C655.149841,440.885681 655.011841,440.487335 655.004883,439.950989 
	C654.962097,438.959564 654.926270,438.504486 654.890442,438.049438 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M472.310059,546.912781 
	C472.525665,546.062073 473.042603,545.150818 473.790649,544.146912 
	C473.551605,544.986877 473.081512,545.919556 472.310059,546.912781 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M585.767334,798.221252 
	C585.891724,799.487122 585.775391,800.968567 585.410034,802.718750 
	C585.282959,801.470642 585.404785,799.953735 585.767334,798.221252 
z"/>
<path fill="#5CC4E0" opacity="1.000000" stroke="none" 
	d="
M805.874329,606.888428 
	C806.276550,607.764709 806.496948,608.585388 806.793335,609.721497 
	C806.162415,609.084412 805.455505,608.131775 805.030579,607.014038 
	C805.312561,606.848999 805.692505,606.832825 805.874329,606.888428 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M481.010498,528.998901 
	C481.396698,528.996460 481.773529,529.000671 482.430511,529.007812 
	C482.343018,529.929138 481.975342,530.847473 481.305481,531.863525 
	C480.993896,531.793091 480.984558,531.625122 480.979156,530.885132 
	C480.986053,529.883423 480.988983,529.453674 480.991882,529.023865 
	C480.991882,529.023865 481.001129,529.005554 481.010498,528.998901 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M655.094299,441.245544 
	C655.608826,441.158081 656.067810,441.430450 656.765137,441.825989 
	C657.037598,442.389557 657.071716,442.829987 657.092468,443.600708 
	C656.398987,443.155792 655.718872,442.380615 655.094299,441.245544 
z"/>
<path fill="#42D2C7" opacity="1.000000" stroke="none" 
	d="
M346.529785,538.670044 
	C346.419891,539.560852 345.993988,540.623474 345.237061,541.831055 
	C345.341919,540.931274 345.777832,539.886597 346.529785,538.670044 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M578.739624,305.906860 
	C578.986206,306.449402 578.989807,306.888855 578.991272,307.657623 
	C578.372314,307.203125 577.755493,306.419281 577.144043,305.306885 
	C577.598450,305.253479 578.047546,305.528625 578.739624,305.906860 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M641.151123,417.182526 
	C641.202820,416.820587 641.622131,416.771851 641.826660,416.717529 
	C642.368530,417.535004 642.705811,418.406769 643.036377,419.625366 
	C642.386292,419.162964 641.742859,418.353729 641.151123,417.182526 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M695.294312,511.045471 
	C695.995300,511.515839 696.592773,512.323547 697.213684,513.455933 
	C696.554993,512.981262 695.872864,512.182068 695.294312,511.045471 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M721.301514,556.042847 
	C721.922546,556.272766 722.461487,556.834351 722.981140,557.710083 
	C722.381104,557.474243 721.800293,556.924316 721.301514,556.042847 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M654.626831,438.003143 
	C654.926270,438.504486 654.962097,438.959564 654.934814,439.752197 
	C654.300720,439.525299 653.729736,438.960815 653.239075,438.065552 
	C653.667297,437.808838 654.015259,437.882843 654.626831,438.003143 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M688.682861,536.963379 
	C688.066345,536.737061 687.526550,536.177307 687.002319,535.302246 
	C687.598450,535.534668 688.179016,536.082336 688.682861,536.963379 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M729.420044,569.979370 
	C730.044739,570.229797 730.536377,570.806458 731.021729,571.685242 
	C730.439331,571.426758 729.863159,570.866150 729.420044,569.979370 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M623.418579,385.314178 
	C623.948242,385.559570 624.432129,386.095947 624.904175,386.942841 
	C624.385742,386.703979 623.879272,386.154572 623.418579,385.314178 
z"/>
<path fill="#42D2C7" opacity="1.000000" stroke="none" 
	d="
M437.330872,474.044861 
	C437.341644,473.484619 437.634766,472.830872 438.193085,472.094849 
	C438.176605,472.658813 437.894928,473.305084 437.330872,474.044861 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M583.424072,316.305328 
	C583.960510,316.530396 584.444519,317.041473 584.929932,317.851379 
	C584.411377,317.630585 583.891541,317.110931 583.424072,316.305328 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M673.360229,473.011902 
	C673.975220,473.246094 674.499146,473.812378 675.007751,474.690063 
	C674.417969,474.448944 673.843567,473.896484 673.360229,473.011902 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M810.560547,614.077209 
	C810.086426,614.325928 809.747375,614.252441 809.168701,613.794128 
	C808.873413,613.002258 808.817688,612.595093 808.761963,612.187988 
	C808.761963,612.187988 808.947815,612.035889 809.040527,611.957764 
	C809.653992,612.504700 810.174805,613.129883 810.560547,614.077209 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M524.628662,873.163208 
	C524.912720,874.014954 524.980957,875.116516 524.991028,876.629272 
	C524.759460,875.831299 524.586121,874.622192 524.628662,873.163208 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M678.669983,519.982056 
	C678.053528,519.724182 677.549866,519.138489 677.070801,518.242310 
	C677.657898,518.505920 678.220337,519.080017 678.669983,519.982056 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M682.760742,526.915771 
	C682.127136,526.698792 681.571533,526.147095 681.030334,525.278809 
	C681.642761,525.501892 682.240845,526.041443 682.760742,526.915771 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M803.050110,603.106323 
	C802.822693,602.638672 802.836975,602.216248 802.846436,601.478027 
	C803.197449,601.621033 803.553162,602.079895 803.846069,602.853638 
	C803.783203,603.168518 803.291748,603.151611 803.050110,603.106323 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M580.818176,835.169678 
	C581.031311,835.481567 581.038147,835.944885 580.952026,836.745056 
	C580.776672,836.494995 580.694275,835.908020 580.818176,835.169678 
z"/>
<path fill="#41D3C6" opacity="1.000000" stroke="none" 
	d="
M444.940796,593.782349 
	C444.447571,593.763794 444.099762,593.533447 443.751953,593.303040 
	C444.055634,593.217285 444.359314,593.131531 444.919495,593.055176 
	C445.146057,593.233215 445.116150,593.401917 444.940796,593.782349 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M672.271301,469.999908 
	C672.041077,469.514832 672.027161,469.084778 672.003235,468.331421 
	C672.160767,468.006317 672.328369,468.004425 672.747192,468.003479 
	C672.993469,468.469360 672.988403,468.934357 672.957520,469.749146 
	C672.931763,470.098938 672.487610,470.054901 672.271301,469.999908 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M578.783020,851.115845 
	C579.022156,851.243774 578.992126,851.512390 578.759827,851.940308 
	C578.393005,851.841797 578.378479,851.560730 578.783020,851.115845 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M657.351685,445.015076 
	C657.687317,444.897308 657.899475,445.121796 658.074829,445.654572 
	C657.849670,445.683960 657.579712,445.482117 657.351685,445.015076 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M684.782776,530.908569 
	C684.539673,530.945923 684.299500,530.720398 684.039673,530.241699 
	C684.275208,530.207642 684.530457,530.426636 684.782776,530.908569 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M683.730103,528.939087 
	C683.395996,529.085876 683.155884,528.867493 682.970825,528.301880 
	C683.200012,528.262146 683.466125,528.468628 683.730103,528.939087 
z"/>
<path fill="#A7E4EB" opacity="1.000000" stroke="none" 
	d="
M804.603882,604.001770 
	C804.278015,604.116882 804.076782,603.905762 803.896057,603.411621 
	C804.116577,603.378235 804.387207,603.560547 804.603882,604.001770 
z"/>
<path fill="#45B1D7" opacity="1.000000" stroke="none" 
	d="
M604.430542,333.763000 
	C605.355103,334.281311 606.058533,335.069611 606.761902,335.857910 
	C606.274719,336.165283 605.787476,336.472656 605.300232,336.780029 
	C604.936584,335.864349 604.572998,334.948669 604.430542,333.763000 
z"/>
<path fill="#45B1D7" opacity="1.000000" stroke="none" 
	d="
M603.162720,326.252777 
	C603.869995,326.863495 604.238220,327.552216 604.606445,328.240936 
	C604.039551,328.390045 603.472656,328.539185 602.905762,328.688324 
	C602.878418,327.902466 602.851013,327.116638 603.162720,326.252777 
z"/>
<path fill="#45B2D7" opacity="1.000000" stroke="none" 
	d="
M548.678650,280.141602 
	C548.842834,280.347534 548.704407,280.597046 548.257446,280.885071 
	C548.113281,280.687408 548.255615,280.441315 548.678650,280.141602 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M716.079468,585.459412 
	C722.719238,585.087769 729.432617,585.083435 736.572998,585.076904 
	C736.525391,586.007568 736.045105,587.753601 735.576904,587.750305 
	C729.546387,587.708862 723.516113,587.446960 717.491333,587.133606 
	C717.023926,587.109314 716.598206,586.281128 716.079468,585.459412 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M705.062988,569.303162 
	C708.390381,574.023499 711.687256,579.136353 715.072144,584.595947 
	C709.725220,581.228088 707.193848,575.584778 705.062988,569.303162 
z"/>
<path fill="#44B9D2" opacity="1.000000" stroke="none" 
	d="
M738.146240,584.927612 
	C737.804077,584.185669 737.854431,583.469177 737.914062,582.393005 
	C738.866394,582.421021 739.809570,582.808716 740.752686,583.196411 
	C740.014709,583.781921 739.276733,584.367493 738.146240,584.927612 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M637.032532,517.993530 
	C637.975769,517.474060 639.125183,517.263367 640.632446,517.113647 
	C639.739746,517.550598 638.489258,517.926514 637.032532,517.993530 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M737.205811,579.859253 
	C736.323853,579.230347 735.695312,578.434326 735.023804,577.325195 
	C735.850220,577.166382 736.719666,577.320618 737.796570,577.738647 
	C737.822388,578.565674 737.640808,579.128967 737.205811,579.859253 
z"/>
<path fill="#F8FEFE" opacity="1.000000" stroke="none" 
	d="
M332.698792,791.717285 
	C327.860901,792.876160 323.285858,793.802917 318.668671,794.407410 
	C318.266602,794.460083 317.121033,792.397461 317.107635,791.307739 
	C316.962921,779.522705 316.873840,767.733826 317.094025,755.952026 
	C317.117615,754.690857 319.170013,752.418335 320.323517,752.390869 
	C331.150635,752.133301 334.943359,753.250854 335.075714,764.897278 
	C335.143585,770.871887 335.282043,776.859924 334.957642,782.818481 
	C334.800385,785.706726 333.650757,788.540833 332.698792,791.717285 
z"/>
<path fill="#F8FEFE" opacity="1.000000" stroke="none" 
	d="
M678.005920,787.000000 
	C678.004395,774.353882 678.004395,762.207764 678.004395,750.133911 
	C688.519287,746.859924 695.507385,751.683899 695.975037,762.737061 
	C696.502686,775.204590 696.747864,787.687988 696.885620,800.167053 
	C697.054810,815.493408 697.070618,830.822815 696.989990,846.150085 
	C696.936768,856.276978 692.908875,859.880310 682.886902,859.876404 
	C679.404968,859.875061 678.764343,858.508484 678.741943,855.442078 
	C678.576172,832.794189 678.269653,810.147217 678.005920,787.000000 
z"/>
<path fill="#F8FEFE" opacity="1.000000" stroke="none" 
	d="
M466.193970,788.999512 
	C466.446259,779.540222 466.448700,770.564331 467.036774,761.627014 
	C467.447906,755.379150 470.291382,752.456604 475.152985,751.975769 
	C479.687958,751.527161 483.152710,752.973145 484.106476,757.819031 
	C484.904022,761.871460 485.781860,765.996033 485.818115,770.095825 
	C486.041656,795.377075 486.068054,820.660400 486.063385,845.942993 
	C486.062866,848.738708 485.622406,851.568054 485.081268,854.321899 
	C484.301270,858.291382 482.040527,860.765625 477.696777,861.087219 
	C473.045319,861.431580 469.867340,859.589294 468.602173,855.127930 
	C467.794769,852.280701 467.158203,849.285400 467.095978,846.342712 
	C466.695312,827.397034 466.474243,808.447632 466.193970,788.999512 
z"/>
<path fill="#45B1D7" opacity="1.000000" stroke="none" 
	d="
M747.725464,510.026154 
	C749.344421,512.089661 750.638245,514.201599 751.971497,516.659668 
	C748.867432,515.764099 746.170166,514.225647 747.725464,510.026154 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M720.296021,550.763306 
	C719.015076,549.238892 717.986938,547.498840 716.874512,545.422485 
	C718.043030,546.906616 719.295959,548.727112 720.296021,550.763306 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M705.212830,524.830933 
	C704.630737,524.932800 704.299377,524.841858 703.746582,524.623535 
	C703.345825,523.580505 703.166626,522.664917 702.961975,521.395142 
	C703.778931,522.240051 704.621277,523.439148 705.212830,524.830933 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M702.970825,520.663696 
	C701.978699,519.607910 700.999878,518.164246 699.976929,516.386230 
	C700.949890,517.459900 701.967041,518.867920 702.970825,520.663696 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M688.986084,496.626831 
	C688.006836,495.573090 687.033691,494.135651 686.007690,492.365417 
	C686.967285,493.436157 687.979736,494.839661 688.986084,496.626831 
z"/>
<path fill="#5CC4E0" opacity="1.000000" stroke="none" 
	d="
M808.555176,612.022949 
	C808.817688,612.595093 808.873413,613.002258 808.916870,613.718567 
	C808.277710,613.239868 807.650818,612.451904 807.033264,611.314209 
	C807.477783,611.262329 807.913025,611.560120 808.555176,612.022949 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M712.961182,538.710815 
	C712.652710,538.545593 712.330383,538.077087 711.972290,537.314331 
	C712.273438,537.482544 712.610352,537.945068 712.961182,538.710815 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M689.971008,498.714661 
	C689.672791,498.544556 689.358276,498.074493 689.013916,497.309937 
	C689.307556,497.481842 689.631165,497.948303 689.971008,498.714661 
z"/>
<path fill="#F4FEFE" opacity="1.000000" stroke="none" 
	d="
M256.830719,820.263794 
	C257.564362,825.923279 258.234619,831.146484 258.959747,836.797241 
	C252.847427,836.797241 247.150436,836.797241 240.430847,836.797241 
	C242.814896,815.695679 245.154968,794.983215 247.495056,774.270813 
	C248.269241,774.231995 249.043427,774.193176 249.817612,774.154297 
	C252.134186,789.378723 254.450760,804.603088 256.830719,820.263794 
z"/>
<path fill="#F4FEFE" opacity="1.000000" stroke="none" 
	d="
M392.582428,836.269897 
	C392.523346,830.483643 392.556824,824.760742 393.179932,819.102844 
	C395.124603,801.445190 397.292267,783.812073 400.140015,766.049377 
	C403.436279,789.096008 406.732544,812.142639 410.196686,836.363037 
	C404.088104,836.363037 398.516449,836.363037 392.582428,836.269897 
z"/>
<path fill="#F4FEFE" opacity="1.000000" stroke="none" 
	d="
M877.409058,785.439026 
	C878.463562,777.097412 879.401184,769.189209 880.338867,761.281006 
	C880.610779,761.235291 880.882690,761.189514 881.154602,761.143799 
	C884.457825,784.237793 887.760986,807.331726 891.124023,830.843811 
	C884.812134,830.843811 879.239502,830.843811 872.564331,830.843811 
	C874.155273,815.710938 875.723755,800.791687 877.409058,785.439026 
z"/>
<path fill="#F4FEFE" opacity="1.000000" stroke="none" 
	d="
M769.725525,814.345520 
	C770.503235,820.331665 771.214172,825.877747 771.976868,831.827332 
	C765.527527,831.827332 759.644775,831.827332 753.373657,831.827332 
	C756.029602,808.417236 758.647705,785.341248 761.265747,762.265198 
	C761.553833,762.223022 761.841919,762.180786 762.130005,762.138611 
	C764.639587,779.394226 767.149170,796.649841 769.725525,814.345520 
z"/>
<path fill="#44B9D2" opacity="1.000000" stroke="none" 
	d="
M670.360352,461.940063 
	C668.772949,459.866882 667.532715,457.737152 666.144775,455.305542 
	C667.294800,455.257538 668.592529,455.511383 670.623413,455.908630 
	C670.674072,455.853058 671.443298,455.009674 672.212585,454.166290 
	C672.760620,454.425812 673.308716,454.685333 673.856750,454.944855 
	C672.807007,457.257751 671.757263,459.570648 670.360352,461.940063 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M664.873840,454.023193 
	C664.174805,453.551025 663.522095,452.680206 662.872925,451.462402 
	C663.557678,451.951782 664.238953,452.788147 664.873840,454.023193 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M646.239319,422.815918 
	C645.669922,422.543152 645.329224,422.093384 644.940918,421.354675 
	C645.418152,421.590149 645.943054,422.114532 646.239319,422.815918 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M662.885498,450.778351 
	C662.293213,450.617615 661.702393,450.127167 661.069702,449.325165 
	C661.647583,449.491974 662.267273,449.970337 662.885498,450.778351 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M649.894653,429.737152 
	C649.681946,429.871185 649.473206,429.725403 649.307617,429.278381 
	C649.509155,429.186707 649.691833,429.346802 649.894653,429.737152 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M659.531494,443.549103 
	C659.476807,443.581451 659.586121,443.516754 659.531494,443.549103 
z"/>
<path fill="#44B8D2" opacity="1.000000" stroke="none" 
	d="
M592.950562,379.347107 
	C595.424194,382.184265 598.021240,385.252014 600.207458,388.588959 
	C603.041443,392.914551 605.727173,397.360626 608.128052,401.937714 
	C609.557434,404.662659 609.860413,408.021454 611.459717,410.606689 
	C617.593201,420.521667 624.201965,430.143372 630.301575,440.078217 
	C632.675842,443.945374 633.962280,448.464508 636.124207,452.482208 
	C638.351440,456.621155 641.086060,460.484100 643.519592,464.515533 
	C648.634644,472.989288 653.720032,481.481201 658.750427,490.005310 
	C659.300720,490.937805 659.412292,492.129242 660.043213,494.274963 
	C652.227051,493.826660 645.042053,493.414581 637.142700,492.708740 
	C633.103699,492.134460 629.779114,491.853943 626.454468,491.573395 
	C626.441162,490.950531 626.427856,490.327698 626.414612,489.704834 
	C629.457214,489.554565 632.499817,489.404297 636.586182,489.202454 
	C634.966614,488.151764 634.182373,487.271973 633.271362,487.111572 
	C626.860718,485.982788 620.412415,485.066772 614.005981,483.916656 
	C612.800659,483.700287 611.755676,482.682281 610.589294,482.135193 
	C608.970337,481.375793 607.351379,480.264038 605.653259,480.094269 
	C601.256042,479.654572 599.768433,477.485107 599.861694,473.166107 
	C599.909302,470.962036 599.168945,467.614899 597.647949,466.722900 
	C593.733154,464.427124 589.184387,463.212494 583.822510,461.157074 
	C584.497864,460.049347 585.338989,458.188049 586.609558,456.691132 
	C588.887878,454.006714 590.145630,451.576233 586.549133,449.014557 
	C580.974976,454.246368 578.677307,454.462646 576.017822,450.162170 
	C578.105713,449.979889 580.193604,449.797577 583.774841,449.484924 
	C581.476807,445.254120 579.692383,441.999817 577.936462,438.730164 
	C573.573730,430.606567 569.364990,422.396149 564.823303,414.374084 
	C561.342163,408.225250 557.351685,402.367096 553.757690,396.279327 
	C547.430969,385.562561 541.286804,374.737976 534.963013,364.019440 
	C531.132629,357.527069 527.070251,351.171906 523.198975,344.703094 
	C522.280579,343.168427 521.710815,341.425140 520.958130,339.407806 
	C521.759888,338.939484 522.600708,338.914154 523.405579,338.730804 
	C527.975525,337.689636 528.485840,338.189453 527.246582,342.576782 
	C525.893127,347.368530 529.001160,351.673157 534.049133,351.998199 
	C541.880066,352.502441 541.967651,352.619629 539.431030,359.725555 
	C540.169861,359.663879 541.044250,359.846222 541.294739,359.531708 
	C545.190552,354.639618 551.945679,352.277924 557.568115,354.208801 
	C558.559875,354.549377 559.456177,355.883484 559.877502,356.961639 
	C560.274536,357.977478 559.994385,359.260986 559.998779,360.426788 
	C560.033813,369.779877 560.230164,369.973572 569.454468,370.006073 
	C570.950928,370.011353 572.453369,370.207031 573.942017,370.121307 
	C576.397827,369.979858 578.844604,369.680756 582.527832,369.331085 
	C583.808167,373.524200 590.206543,377.304688 586.704712,384.995361 
	C587.342285,385.099243 587.979858,385.203125 588.617432,385.307007 
	C590.042053,383.437134 591.466675,381.567261 592.950562,379.347107 
z"/>
<path fill="#41CBC9" opacity="1.000000" stroke="none" 
	d="
M639.110168,631.287964 
	C625.677917,631.459351 612.322144,631.321045 598.540771,631.185120 
	C597.004700,629.722717 595.700439,628.364563 594.824707,626.770813 
	C593.037964,623.519165 590.313171,622.878845 586.866089,622.942200 
	C578.821167,623.090088 578.475159,622.776245 578.054077,614.708984 
	C577.818909,610.202026 575.820801,608.112976 571.302856,607.933960 
	C568.704834,607.830994 566.132263,607.086243 562.644470,606.470886 
	C565.466614,602.213501 555.621460,596.458740 566.311279,592.960876 
	C564.619141,592.528137 563.703857,592.104065 562.782104,592.089478 
	C557.207397,592.000854 551.626831,592.113342 546.059692,591.881165 
	C545.048035,591.838989 544.089783,590.514954 543.523071,589.391052 
	C555.959473,589.001282 567.979736,589.003113 580.000000,589.004883 
	C580.458008,589.630188 580.894592,590.781616 581.377136,590.801208 
	C587.770874,591.060669 594.171692,591.134766 600.569275,591.309570 
	C606.327087,591.466919 612.082703,591.702087 617.840027,591.878967 
	C619.378052,591.926208 620.918762,591.886108 622.458252,591.886108 
	C622.488342,592.473572 622.518433,593.060974 622.548584,593.648438 
	C619.067688,594.100464 615.595154,594.818298 612.103455,594.928772 
	C608.694153,595.036621 607.811829,596.694519 608.008179,599.680786 
	C608.440918,606.265442 615.379456,611.223328 621.562378,608.656189 
	C623.294922,607.936768 623.920044,604.477173 624.980591,602.240112 
	C625.580933,600.973694 626.033691,599.637329 626.552917,598.332520 
	C627.954224,598.974609 629.709534,599.290588 630.672913,600.336182 
	C632.073608,601.856628 632.498474,604.426147 634.051697,605.628174 
	C638.974548,609.438110 638.453613,615.920959 641.842590,620.384399 
	C644.222717,623.519165 643.316895,627.361206 639.110168,631.287964 
z"/>
<path fill="#3CD7C2" opacity="1.000000" stroke="none" 
	d="
M468.888031,595.777405 
	C469.405670,594.679138 469.923279,593.580872 470.625732,592.231873 
	C473.630615,588.883362 476.450592,585.785522 479.670166,582.248657 
	C480.304138,583.358276 481.817047,584.767090 481.696808,586.019165 
	C481.273132,590.430908 480.182983,594.774353 479.578644,599.175659 
	C479.382019,600.607727 479.898987,602.137695 479.993073,602.871155 
	C483.611389,601.804199 486.623474,600.655029 489.741333,600.061951 
	C493.735107,599.302368 496.508179,600.651489 497.126007,605.214050 
	C497.262909,606.225098 498.496552,607.591431 499.500275,607.900146 
	C506.513855,610.057983 510.915436,614.765015 513.819519,621.323975 
	C514.349792,622.521729 516.147034,623.587402 517.533997,623.876343 
	C519.606995,624.308289 521.839905,623.903992 523.995789,624.021057 
	C528.771729,624.280273 530.163269,626.719604 528.008057,631.235840 
	C506.673309,631.652710 485.351471,631.769104 464.029419,631.848511 
	C460.559326,631.861450 457.088593,631.710632 453.255493,631.061035 
	C452.189758,629.546448 451.486725,628.606323 450.783691,627.666199 
	C453.826569,622.002258 456.869446,616.338379 460.332001,610.305542 
	C461.430328,608.837524 462.109009,607.738403 462.787689,606.639221 
	C462.787689,606.639221 462.791718,606.303284 463.129730,606.134644 
	C463.620453,605.277283 463.773163,604.588501 463.925842,603.899719 
	C464.999725,602.025269 466.073578,600.150757 467.737122,597.972534 
	C468.513855,597.038330 468.700928,596.407837 468.888031,595.777405 
z"/>
<path fill="#32C5C1" opacity="1.000000" stroke="none" 
	d="
M580.468628,589.002869 
	C567.979736,589.003113 555.959473,589.001282 543.470825,589.002197 
	C538.360840,588.314880 533.177307,590.762207 529.090454,586.217285 
	C542.151062,585.913025 555.254883,586.040222 568.359009,586.078064 
	C583.459045,586.121643 598.560181,585.995667 613.659180,586.126465 
	C620.440674,586.185242 627.218201,586.688965 634.420227,587.073730 
	C634.917358,587.554260 634.991821,587.951721 635.107910,588.649841 
	C617.078735,588.967285 599.008057,588.984070 580.468628,589.002869 
z"/>
<path fill="#41CBC9" opacity="1.000000" stroke="none" 
	d="
M574.891602,631.416138 
	C573.272461,631.819763 571.565369,631.883423 569.439026,631.858826 
	C570.614502,628.763184 572.683472,629.706421 574.891602,631.416138 
z"/>
<path fill="#42C8CB" opacity="1.000000" stroke="none" 
	d="
M532.820618,576.475586 
	C532.331848,577.123657 531.911560,577.450562 531.257568,577.905640 
	C531.605347,576.071594 529.450012,572.807983 533.841431,572.823730 
	C533.666138,574.112488 533.277649,575.133423 532.820618,576.475586 
z"/>
<path fill="#42C8CB" opacity="1.000000" stroke="none" 
	d="
M499.229614,542.941101 
	C499.342285,542.044739 499.798248,541.136414 500.507019,540.047241 
	C500.364197,540.887268 499.968567,541.908203 499.229614,542.941101 
z"/>
<path fill="#42C8CB" opacity="1.000000" stroke="none" 
	d="
M535.786377,569.078979 
	C535.920959,569.612366 535.746582,570.151733 535.351929,570.876770 
	C535.246948,570.403259 535.362183,569.744141 535.786377,569.078979 
z"/>
<path fill="#42C8CB" opacity="1.000000" stroke="none" 
	d="
M530.786011,577.936096 
	C530.861328,578.587341 530.686218,579.141785 530.273071,579.873413 
	C530.201843,579.313538 530.368774,578.576355 530.786011,577.936096 
z"/>
<path fill="#42C8CB" opacity="1.000000" stroke="none" 
	d="
M536.759766,568.122192 
	C536.823059,568.297485 536.668274,568.588440 536.280334,568.976562 
	C536.107361,568.733093 536.272217,568.454529 536.759766,568.122192 
z"/>
<path fill="#44BDD0" opacity="1.000000" stroke="none" 
	d="
M511.968567,520.806152 
	C512.197327,519.715759 512.781067,518.553528 513.680908,517.303345 
	C513.439209,518.388306 512.881348,519.561340 511.968567,520.806152 
z"/>
<path fill="#3AE2BD" opacity="1.000000" stroke="none" 
	d="
M402.998444,629.002441 
	C395.837585,629.004333 389.047302,628.161682 382.537537,629.155884 
	C355.776703,633.242859 328.903717,629.854492 302.112854,630.952454 
	C296.418213,631.185913 295.390381,629.612366 298.342346,624.486572 
	C304.658478,613.519592 311.426910,602.814026 317.905609,591.939148 
	C320.545288,587.508179 322.738159,582.806641 325.455414,578.427490 
	C328.090149,574.181335 331.108063,570.170349 334.015717,566.097534 
	C335.303253,564.294006 336.733643,562.592590 338.074554,560.876343 
	C341.665344,560.505737 345.591156,560.100525 350.414124,559.602722 
	C353.533264,561.845825 354.373505,565.852478 354.007996,570.375366 
	C353.663422,574.639160 354.713226,577.011292 359.939941,577.229858 
	C365.563049,577.465149 369.918396,580.991516 372.904083,585.924011 
	C373.367798,586.690002 374.232483,587.894775 374.773254,587.824951 
	C379.128845,587.262207 380.958160,589.670166 383.576294,592.859375 
	C386.432007,596.337952 385.907379,599.593567 385.923157,603.052795 
	C385.942474,607.285950 387.674988,608.760559 391.887177,609.121338 
	C398.882843,609.720459 405.841125,610.947388 412.734497,612.315979 
	C413.627197,612.493164 414.449066,615.048889 414.577423,616.564331 
	C414.755096,618.662354 413.955353,620.837585 414.093201,622.945801 
	C414.319641,626.408691 415.294312,626.918579 419.487823,626.418274 
	C419.487823,624.784119 419.487823,623.132568 419.487823,621.481018 
	C419.950226,621.364685 420.412628,621.248413 420.875031,621.132080 
	C421.432404,622.956604 422.843292,625.117798 422.295593,626.506470 
	C421.796021,627.773315 419.213135,628.771423 417.479279,628.891846 
	C412.838684,629.214294 408.160950,629.002197 402.998444,629.002441 
z"/>
<path fill="#3AE2BD" opacity="1.000000" stroke="none" 
	d="
M373.507263,578.696777 
	C372.725281,579.051941 372.078278,579.144287 371.431274,579.236694 
	C371.653809,578.701111 371.876312,578.165527 372.098816,577.629944 
	C372.613251,577.897949 373.127747,578.165955 373.507263,578.696777 
z"/>
<path fill="#44C1CF" opacity="1.000000" stroke="none" 
	d="
M575.602783,450.166351 
	C578.677307,454.462646 580.974976,454.246368 586.549133,449.014557 
	C590.145630,451.576233 588.887878,454.006714 586.609558,456.691132 
	C585.338989,458.188049 584.497864,460.049347 583.822510,461.157074 
	C589.184387,463.212494 593.733154,464.427124 597.647949,466.722900 
	C599.168945,467.614899 599.909302,470.962036 599.861694,473.166107 
	C599.768433,477.485107 601.256042,479.654572 605.653259,480.094269 
	C607.351379,480.264038 608.970337,481.375793 610.589294,482.135193 
	C611.755676,482.682281 612.800659,483.700287 614.005981,483.916656 
	C620.412415,485.066772 626.860718,485.982788 633.271362,487.111572 
	C634.182373,487.271973 634.966614,488.151764 636.586182,489.202454 
	C632.499817,489.404297 629.457214,489.554565 626.414612,489.704834 
	C626.427856,490.327698 626.441162,490.950531 626.454468,491.573395 
	C629.779114,491.853943 633.103699,492.134460 636.713745,492.707520 
	C632.954651,493.333191 628.914612,493.859436 624.864441,493.955994 
	C618.148621,494.116119 611.426270,493.999939 604.706665,494.000000 
	C586.117493,494.000122 567.528381,494.000183 548.470825,493.999512 
	C547.701599,488.076691 544.555481,486.067505 538.845581,486.912659 
	C536.796326,487.216034 534.603638,486.460358 532.468201,486.292023 
	C528.868713,486.008240 527.641113,484.745544 528.715942,480.776794 
	C529.809143,476.740234 527.278015,474.151093 523.205078,474.010040 
	C517.957764,473.828339 513.303772,473.191559 512.430481,466.414764 
	C512.260864,465.098511 509.550201,463.854614 507.795074,463.049622 
	C503.855499,461.242737 497.641602,463.988342 496.177185,457.075775 
	C496.060669,456.525726 494.116974,455.953217 493.101807,456.071686 
	C487.792938,456.691284 483.090240,455.759216 479.212708,451.250793 
	C480.561829,450.664459 482.132233,450.090240 483.703979,450.086578 
	C508.625641,450.028351 533.547424,450.035583 558.469238,450.047394 
	C564.042114,450.050018 569.614929,450.127502 575.602783,450.166351 
z"/>
<path fill="#41CBC9" opacity="1.000000" stroke="none" 
	d="
M474.000000,526.574707 
	C472.675690,526.998352 471.351410,526.999756 470.027100,526.999817 
	C460.812256,527.000427 460.864471,526.995178 459.938293,517.695374 
	C459.801300,516.320190 458.900604,515.021179 458.176605,513.343445 
	C465.305328,510.479645 474.302368,512.449524 474.000000,526.574707 
z"/>
<path fill="#41CBC9" opacity="1.000000" stroke="none" 
	d="
M453.958374,512.630310 
	C452.021179,512.344299 450.042114,511.691071 448.489655,511.178650 
	C446.438141,514.595276 444.577454,517.694092 442.529480,521.104858 
	C440.256836,516.609680 441.035309,514.942688 448.855408,506.301331 
	C453.417664,505.908661 451.759521,510.582581 453.958374,512.630310 
z"/>
<path fill="#41DCC1" opacity="1.000000" stroke="none" 
	d="
M415.364990,555.838745 
	C417.697632,552.079712 420.032379,548.651062 423.289764,543.867493 
	C423.413422,546.658325 424.112244,548.604858 423.428711,549.626282 
	C421.471008,552.551575 420.308868,556.818604 415.364990,555.838745 
z"/>
<path fill="#41C9CA" opacity="1.000000" stroke="none" 
	d="
M478.841064,533.781982 
	C478.544525,534.330505 478.077087,534.647156 477.313965,534.986816 
	C477.568939,534.523254 478.119568,534.036743 478.841064,533.781982 
z"/>
<path fill="#41C9CA" opacity="1.000000" stroke="none" 
	d="
M480.768127,529.158081 
	C480.988983,529.453674 480.986053,529.883423 480.957001,530.633789 
	C480.802032,530.400269 480.673187,529.846313 480.768127,529.158081 
z"/>
<path fill="#42D2C7" opacity="1.000000" stroke="none" 
	d="
M395.916748,532.383423 
	C396.777374,533.326904 397.296082,534.260376 398.324127,536.110474 
	C396.354156,535.667908 395.356964,535.443909 394.359802,535.219849 
	C394.764801,534.271057 395.169800,533.322205 395.916748,532.383423 
z"/>
<path fill="#42D2C7" opacity="1.000000" stroke="none" 
	d="
M378.474365,505.307983 
	C378.468475,504.551788 378.673828,504.015259 378.879150,503.478729 
	C379.153839,503.613800 379.428497,503.748840 379.703186,503.883911 
	C379.363983,504.431824 379.024780,504.979736 378.474365,505.307983 
z"/>
<path fill="#44B8D2" opacity="1.000000" stroke="none" 
	d="
M655.467224,482.450348 
	C655.522339,482.417542 655.412109,482.483154 655.467224,482.450348 
z"/>
<path fill="#41CBC9" opacity="1.000000" stroke="none" 
	d="
M638.540283,599.354919 
	C638.156982,599.881958 637.638062,600.145020 637.119202,600.408142 
	C636.895447,599.869812 636.671692,599.331482 636.447937,598.793152 
	C637.100159,598.892395 637.752441,598.991699 638.540283,599.354919 
z"/>
<path fill="#41CBC9" opacity="1.000000" stroke="none" 
	d="
M643.474243,612.307922 
	C643.468506,611.551697 643.673889,611.015137 643.879333,610.478577 
	C644.153992,610.613647 644.428711,610.748779 644.703369,610.883850 
	C644.364014,611.431763 644.024719,611.979675 643.474243,612.307922 
z"/>
<path fill="#57C4E1" opacity="1.000000" stroke="none" 
	d="
M581.999023,833.454895 
	C581.996460,832.989990 582.023926,832.494690 582.393311,832.183716 
	C582.839417,829.909363 582.916016,827.945923 582.992676,825.982544 
	C582.992126,825.813843 582.991516,825.645142 583.032410,825.226379 
	C583.910522,820.495728 584.747192,816.015259 585.304688,811.270386 
	C585.029724,810.558044 585.033875,810.110107 585.098633,809.330933 
	C586.104614,805.881104 587.292358,802.812256 587.944946,799.633667 
	C589.313904,792.965149 590.524353,786.257446 591.541077,779.526733 
	C591.922241,777.003540 591.373413,774.351990 591.614014,771.793335 
	C592.309875,764.395142 592.945435,756.976074 594.087830,749.640503 
	C594.830872,744.869507 596.417969,740.230042 597.551758,735.823730 
	C605.241455,735.823730 612.773804,735.823730 621.455322,735.823730 
	C623.676086,747.413391 626.762634,758.425415 627.719055,769.619446 
	C629.473999,790.160400 634.920654,810.014221 637.690552,830.342773 
	C639.501770,843.635742 641.718445,856.875244 643.895447,870.115356 
	C644.435303,873.398499 643.421631,875.386841 640.156494,875.729675 
	C637.191895,876.040833 633.534607,876.979675 631.359863,875.676880 
	C628.892822,874.198975 626.999939,870.737610 626.188538,867.774109 
	C625.115845,863.856567 625.506958,859.560852 624.930542,855.475403 
	C624.627563,853.327820 623.604187,851.281860 623.039307,849.589111 
	C613.431152,849.589111 604.696655,849.589111 596.852661,849.589111 
	C596.524536,853.703613 596.198242,857.911926 595.850281,862.118469 
	C595.597595,865.174500 594.890320,868.265991 595.157715,871.273071 
	C595.475647,874.848450 593.230835,875.312988 590.828186,875.981384 
	C590.673035,876.024597 590.495605,875.980164 590.330261,875.995239 
	C578.015320,877.118408 578.015320,877.118530 577.984070,863.414795 
	C577.972778,862.943420 577.978333,862.470276 578.009521,862.235718 
	C578.693054,860.874268 579.515930,859.810669 579.962830,858.607422 
	C580.941772,855.971741 581.730225,853.265198 582.464722,850.994873 
	C582.305542,847.702759 582.106934,844.544312 582.014709,841.382751 
	C581.942200,838.897095 582.000977,836.407593 581.999023,833.454895 
M609.195923,763.988525 
	C609.123901,764.329224 609.051941,764.669922 608.421692,765.518494 
	C607.002747,770.989014 605.454590,776.432617 604.305969,781.959290 
	C604.136292,782.775574 605.857117,783.984680 606.804871,785.552002 
	C606.657532,786.061584 606.510193,786.571106 605.673706,787.330872 
	C601.011108,789.629700 604.504639,793.395508 604.101807,796.438232 
	C602.503052,808.512268 601.033997,820.605042 599.740784,832.714844 
	C599.633728,833.717773 601.185303,835.834595 601.999207,835.851624 
	C608.810364,835.993896 615.629272,835.765564 622.604858,835.646240 
	C621.573669,827.571167 620.834595,820.770020 619.813843,814.011353 
	C617.951416,801.680115 615.899841,789.377441 613.974182,777.055542 
	C613.261719,772.496887 612.739929,767.907837 611.984741,763.356995 
	C611.856140,762.582031 610.985596,761.930176 610.457825,761.221436 
	C610.011230,761.970703 609.564575,762.719971 609.195923,763.988525 
M618.731873,781.902771 
	C618.232544,781.957703 617.733276,782.012634 617.233948,782.067566 
	C617.348755,782.514832 617.463562,782.962097 617.578369,783.409363 
	C618.004639,783.106628 618.430847,782.803955 618.731873,781.902771 
z"/>
<path fill="#41D2C6" opacity="1.000000" stroke="none" 
	d="
M450.518127,627.766296 
	C451.486725,628.606323 452.189758,629.546448 452.899048,630.846558 
	C452.185913,631.199829 451.466522,631.193054 450.365540,630.848694 
	C449.700317,630.024109 449.416626,629.537170 449.132965,629.050232 
	C449.506134,628.655579 449.879333,628.260986 450.518127,627.766296 
z"/>
<path fill="#41D2C6" opacity="1.000000" stroke="none" 
	d="
M462.500793,606.775818 
	C462.109009,607.738403 461.430328,608.837524 460.441742,609.967346 
	C460.825836,608.969482 461.519897,607.940979 462.500793,606.775818 
z"/>
<path fill="#41D2C6" opacity="1.000000" stroke="none" 
	d="
M463.690704,604.091492 
	C463.773163,604.588501 463.620453,605.277283 463.188293,605.977295 
	C463.091095,605.420105 463.273346,604.851685 463.690704,604.091492 
z"/>
<path fill="#41D2C6" opacity="1.000000" stroke="none" 
	d="
M468.645874,595.978394 
	C468.700928,596.407837 468.513855,597.038330 467.998535,597.782532 
	C467.914764,597.323975 468.159241,596.751648 468.645874,595.978394 
z"/>
<path fill="#3CD7C2" opacity="1.000000" stroke="none" 
	d="
M449.060425,629.364197 
	C449.416626,629.537170 449.700317,630.024109 450.006989,630.809204 
	C449.682617,630.630920 449.335236,630.154541 449.060425,629.364197 
z"/>
<path fill="#5CC4E0" opacity="1.000000" stroke="none" 
	d="
M582.749146,826.274109 
	C582.916016,827.945923 582.839417,829.909363 582.421936,831.937378 
	C582.222656,830.189880 582.364136,828.377808 582.749146,826.274109 
z"/>
<path fill="#F4FEFE" opacity="1.000000" stroke="none" 
	d="
M608.979919,765.010620 
	C609.051941,764.669922 609.123901,764.329224 609.420959,763.930603 
	C612.454712,782.591736 615.276001,801.309021 618.063110,820.031250 
	C618.733704,824.535828 619.291382,829.057251 619.944885,833.895752 
	C613.436279,833.895752 607.681335,833.895752 601.596436,833.895752 
	C603.205933,818.087646 604.784363,802.584167 606.362793,787.080688 
	C606.510193,786.571106 606.657532,786.061584 606.868774,784.862793 
	C607.615051,777.785950 608.297485,771.398315 608.979919,765.010620 
z"/>
<path fill="#5CC4E0" opacity="1.000000" stroke="none" 
	d="
M606.018250,787.205811 
	C604.784363,802.584167 603.205933,818.087646 601.596436,833.895752 
	C607.681335,833.895752 613.436279,833.895752 619.944885,833.895752 
	C619.291382,829.057251 618.733704,824.535828 618.063110,820.031250 
	C615.276001,801.309021 612.454712,782.591736 609.381958,763.671021 
	C609.564575,762.719971 610.011230,761.970703 610.457886,761.221436 
	C610.985596,761.930176 611.856140,762.582031 611.984741,763.356995 
	C612.739929,767.907837 613.261719,772.496887 613.974182,777.055542 
	C615.899841,789.377441 617.951416,801.680115 619.813843,814.011353 
	C620.834595,820.770020 621.573669,827.571167 622.604858,835.646240 
	C615.629272,835.765564 608.810364,835.993896 601.999207,835.851624 
	C601.185303,835.834595 599.633728,833.717773 599.740784,832.714844 
	C601.033997,820.605042 602.503052,808.512268 604.101807,796.438232 
	C604.504639,793.395508 601.011108,789.629700 606.018250,787.205811 
z"/>
<path fill="#5CC4E0" opacity="1.000000" stroke="none" 
	d="
M608.700806,765.264526 
	C608.297485,771.398315 607.615051,777.785950 606.817932,784.592773 
	C605.857117,783.984680 604.136292,782.775574 604.305969,781.959290 
	C605.454590,776.432617 607.002747,770.989014 608.700806,765.264526 
z"/>
<path fill="#5CC4E0" opacity="1.000000" stroke="none" 
	d="
M618.794495,782.202026 
	C618.430847,782.803955 618.004639,783.106628 617.578369,783.409363 
	C617.463562,782.962097 617.348755,782.514832 617.233948,782.067627 
	C617.733276,782.012634 618.232544,781.957703 618.794495,782.202026 
z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
